import React, { useEffect, useState } from "react";
import { db } from "../Firebase Functions/firebase"
import { collection, getDocs } from "firebase/firestore";
import "../AdminTaskList.css";

const ITEMS_PER_PAGE = 5;

const VehicleInspectionList = () => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [trucks, setTrucks] = useState([]);

  useEffect(() => {
    const fetchInspectionsAndTrucks = async () => {
      try {
        // Fetch Inspections
        const inspectionsSnapshot = await getDocs(collection(db, "vehicleInspections"));
        let inspectionsData = inspectionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch Trucks
        const trucksSnapshot = await getDocs(collection(db, "Trucks"));
        const trucksData = trucksSnapshot.docs.map((doc) => ({
          id: doc.id, // Truck document ID
          ...doc.data(),
        }));

        console.log("Fetched Inspections:", inspectionsData);
        console.log("Fetched Trucks:", trucksData);

        // Merge truck details into inspections
        let mergedData = inspectionsData.map((inspection) => {
          const matchedTruck = trucksData.find((truck) => truck.id === inspection.truckId) || {};
          return {
            ...inspection,
            vehicleName: matchedTruck.vehicleName || "Unknown",
            assignedTo: matchedTruck.assignedTo || "Unassigned",
          };
        });

        // ✅ Sort inspections by date (latest first)
        mergedData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // Descending order
        });

        setInspections(mergedData);
      } catch (error) {
        console.error("Error fetching inspections or trucks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInspectionsAndTrucks();
  }, []);

  if (loading) {
    return <p>Loading vehicle inspections...</p>;
  }

  // Pagination logic
  const totalPages = Math.ceil(inspections.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentInspections = inspections.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <div className="admin-task-list">
      {currentInspections.map((inspection) => {
        if (!inspection || Object.keys(inspection).length === 0) {
          return <div key={Math.random()} className="admin-task-card">❌ Invalid Inspection Data</div>;
        }

        return (
          <div key={inspection.id} className="admin-task-card">
            <h3>{inspection?.inspectionType?.toUpperCase() || "UNKNOWN"} Inspection</h3>
            <p><strong>Vehicle:</strong> {inspection.vehicleName}</p>
            <p><strong>Assigned To:</strong> {inspection.assignedTo}</p>
            <p><strong>Truck ID:</strong> {inspection?.truckId || "N/A"}</p>
            <p><strong>Date:</strong> {inspection?.date || "Unknown"} | <strong>Time:</strong> {inspection?.time || "N/A"}</p>

            {/* Checklist Items */}
            {inspection?.checklist && (
              <div className="admin-task-detail">
                <h4>Checklist</h4>
                {Object.entries(inspection.checklist).map(([item, status], index) => (
                  <p key={index}>
                    <strong>{item.replace(/_/g, " ")}:</strong> {status ? "✅ Passed" : "❌ Failed"}
                  </p>
                ))}
              </div>
            )}

            {/* Trailer Checklist (if applicable) */}
            {inspection?.trailerChecklist && (
              <div className="admin-task-detail">
                <h4>Trailer Checklist</h4>
                {Object.entries(inspection.trailerChecklist).map(([item, status], index) => (
                  <p key={index}>
                    <strong>{item.replace(/_/g, " ")}:</strong> {status ? "✅ Passed" : "❌ Failed"}
                  </p>
                ))}
              </div>
            )}

            {/* Comments & Signature */}
            {inspection?.comments && (
              <p><strong>Comments:</strong> {inspection.comments}</p>
            )}
            {inspection?.signature && (
              <div>
                <p><strong>Signature:</strong></p>
                <img src={inspection.signature} alt="Signature" className="admin-task-image" />
              </div>
            )}
          </div>
        );
      })}

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          className="pagination-button"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          className="pagination-button"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default VehicleInspectionList;
