import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { ChemicalApplicationPDF } from '../PDF Functions/ChemAppPDF';
import QuickbaseUpdate from '../Quickbase Functions/QBChemForm';
import { collection, addDoc, where, query, getDocs } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { db } from "../Firebase Functions/firebase";
import { sendEmailNotification } from '../Email Functions/Email Sender/SprayEmail';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  text-align: center;
`;


const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
`;

const Checkbox = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem; /* Add space between buttons */
  margin-top: 1rem; /* Space above the buttons */
`;

const CloseButton = styled(Button)`
  background-color: #e74c3c; /* Red background for better visibility */
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
`;

const PDFContainer = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 80%;
  background: white;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  overflow: hidden;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.div`
  color: #27ae60;
  margin-bottom: 1rem;
`;

const Select = styled.select`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TechnicianDashboard = () => {
  const navigate = useNavigate();
  const [manualEmail, setManualEmail] = useState('');
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [pendingFormData, setPendingFormData] = useState(null);
  const [newRecord, setNewRecord] = useState({
    applicationDate: '',
    applicator: '',
    ownerOperatorNumber: '',
    applicatorId: '',
    project: '',
    address: '',
    city: '',
    state: '',
    temperature: '',
    wind: '',
    areaTreated: '',
    areaUnit: '',
    usedRestrictedPesticide: '',
    chemicals: [
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
    ],
    checkboxes: {
      turfFertilizer: false,
      turfPostEmerge: false,
      turfPreEmerge: false,
      turfInsect: false,
      antControl: false,
      bedFertilizer: false,
      bedPostEmerge: false,
      bedPreEmerge: false,
      tsInsect: false,
      ccPostEmerge: false,
      tsDisease: false,
      tsFertilizer: false,
      palmFertilizer: false,
      palmFungicide: false,
    },
    notes: '',
  });

  const [showPDF, setShowPDF] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  
  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) return;
  
      const q = query(collection(db, 'Users'), where('email', '==', currentUser.email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        const userType = userData.type;
  
        setNewRecord((prev) => ({ ...prev, userType })); // Store it in the form state
      }
    };
  
    fetchUserRole();
  }, []);

  

  const chemicals = {
    Antitranspirants: ['Trans Film - label', 'Trans Film - SDS'],
    Fungicides: [
      'Dithane 75 DF RAINSHIELD - label',
      'Dithane 75 DF RAINSHIELD - SDS',
      'Eagle 20 EW (myclobutanil)- label',
      'Eagle 20 EW (myclobutanil) - SDS',
      'Headway (axoxystrobin) -label',
      'Heritage (azoxystrobin) -label',
      'Lesco Mancozeb 4F (mancozeb) - label',
      'Mefenoxam 2 AQ (mefenoxam) label',
      'Mefenoxam 2 AQ (mefenoxam) - label',
      'Myclobutanil 20 EW - label',
      'Myclobutanil 20 EW - SDS',
      'Pedigree SC (flutolanil) -label',
      'Pedigree SC (flutolanil) - SDS',
      'Propiconazole 14.3 (propiconazole) - label',
      'Propiconazole 14.3 (propiconazole) - SDS',
      'Strobe 2 L (axoystrobin) - label',
      'Subdue Maxx (mefenoxam) -label',
      'Transom 4.5 F (thiophanate-methyl) - label',
      'Transom 4.5 F (thiophanate-methyl) -SDS',
      'T-Storm F (thiophanate-methyl) - label',
      'T-Store F (thiophanate-methyl) -SDS',
      'Turfcide 10 G (PCNB) -SDS',
      'Turfcide 10 G (PCNB) -label',
      'Xzemplar (fluxapyroxad) - label',
      'Xzemplar (fluxapyroxad) - SDS',
    ],
    GrowthRegulators: [
      'Pac-Low (paclobutrazole) - label',
      'Tide Paclo 2 SC (paclobutrazole) - label',
      'Pac-Low (paclobutrazole) -label',
      'Pac-Low (pacloburtrazole) - label',
      'Tide Paclo 2 SC (paclobutrazole) - label (a)',
      'Tide Paclo 2 SC (paclobutrazole) -SDS',
      'Tide Paclo 2 SC (paclobutrazole) - label',
      'Trim Tect (paclobutrazole) - label',
      'Trim Tect (paclobutrazole) - SDS',
    ],
    Herbicides: [
      'Avenue South (4-Way) - label',
      'Barricade 4FL (prodiamine) - label',
      'Blindside (sulfentrazone) - label',
      'Celcius WG (indosulfuron) - label (a)',
      'Certainty (sulfosulfuron) - label',
      'Drive XLR8 (auinclorac) - label',
      'Fusilade II T&O (fluazifop) - label',
      'Lesco Three-Way (3 Way) - label',
      'MSM Turf (metsulfuron) - label',
      'Speed Zone Southern (4-Way) - label',
    ],
    Insecticides: [
      'Acelepryn (chlorantraniliprole) - label',
      'Acephate 90 Prills (acephate) - label',
      'Addvion Fire Ant Bait (indoxibarb) - label',
      'Arena 50 WDG(clothianidin) - label',
      'Cross Check Plus (bifenthrin) - label',
    ],
    Surfactants: ['Lesco Methylated Seed Oil - label', 'Lesco Methylated Seed Oil - SDS'],
  };

  const handleChemicalChange = (index, e) => {
    const { name, value } = e.target;
    setNewRecord((prev) => {
      const updatedChemicals = [...prev.chemicals];
      updatedChemicals[index][name] = value;

      // Reset product if category changes
      if (name === 'category') {
        updatedChemicals[index].product = '';
      }

      return { ...prev, chemicals: updatedChemicals };
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const generatePdfBlob = async () => {
    return await pdf(<ChemicalApplicationPDF recordData={newRecord} />).toBlob();
  };

  // Converts a Blob to Base64
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract Base64 string
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewRecord((prev) => {
      const updatedCheckboxes = {
        ...prev.checkboxes,
        [name]: checked,
      };
      console.log('Updated Checkboxes:', updatedCheckboxes); // Debug log
      return {
        ...prev,
        checkboxes: updatedCheckboxes,
      };
    });
  };

  const handlePDFPreview = async () => {
    await generatePdfBlob(); // Ensure the Blob is generated
    setShowPDF(true);
  };

  const handleNotifyReviewNeeded = async (form) => {
    const reviewLink = `https://rciservicenow.com/chemical-review/${form.id}`;
  
    const to = manualEmail;
    const subject = `Review Needed: Chemical Application for ${form.project}`;
    const text = `Please review the updated chemical application form.\n\nLink: ${reviewLink}`;
    const html = `<p>Please review the updated chemical application form.</p><p><a href="${reviewLink}">Click here to view and approve</a></p>`;
  
    try {
      await sendEmailNotification({ to: manualEmail, // could be a single or comma-separated list
        subject: `Review Needed: Chemical Application for ${form.project}`,
        text: `Please review the updated chemical application form.\n\nLink: ${reviewLink}`,
        html: `<p>Please review the updated chemical application form.</p><p><a href="${reviewLink}">Click here to view and approve</a></p>`,
      });
      alert('Email notification sent!');
    } catch (error) {
      alert('Failed to send email.');
    }
  };
  
 
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const blob = await generatePdfBlob();
      const pdfBase64 = await convertBlobToBase64(blob);
  
      const [firstName, ...lastNameParts] = newRecord.applicator.trim().split(/\s+/);
      const lastName = lastNameParts.join(" ");
  
      const formData = {
        ...newRecord,
        timestamp: new Date(),
        pdfUrl: pdfBase64,
        status: "pending",
        applicatorFirstName: firstName,
        applicatorLastName: lastName,
      };
  
      const docRef = await addDoc(collection(db, "ChemAppForms"), formData);
  
      // Save the form data and show the email modal
      setPendingFormData({ ...formData, id: docRef.id });
      setShowEmailModal(true);
    } catch (error) {
      console.error("❌ Error saving form:", error);
      alert("An error occurred. Please try again.");
    }
  };
  
  
  
  



  const handlePDFClose = () => {
    setShowPDF(false);
  };

const handleCloseModal = () => {
  setSuccessModalVisible(false);
  setManualEmail('');
  navigateBasedOnRole(newRecord.userType);
};


  const checkboxLabels = [
    { name: 'turfFertilizer', label: 'Turf Fertilizer' },
    { name: 'turfPostEmerge', label: 'Turf Post-Emerge' },
    { name: 'turfPreEmerge', label: 'Turf Pre-Emerge' },
    { name: 'turfInsect', label: 'Turf Insect' },
    { name: 'antControl', label: 'Ant Control' },
    { name: 'bedFertilizer', label: 'Bed Fertilizer' },
    { name: 'bedPostEmerge', label: 'Bed Post-Emerge' },
    { name: 'bedPreEmerge', label: 'Bed Pre-Emerge' },
    { name: 'tsInsect', label: 'T/S Insect' },
    { name: 'ccPostEmerge', label: 'C/C Post Emerge' },
    { name: 'tsDisease', label: 'T/S Disease' },
    { name: 'tsFertilizer', label: 'T/S Fertilizer' },
    { name: 'palmFertilizer', label: 'Palm Fertilizer' },
    { name: 'palmFungicide', label: 'Palm Fungicide' },
  ];

  const operators = {
    Alabama: [
      { id: "2002514", name: "Jeremy Carter" },
      { id: "2004088", name: "Charles Hollingsworth(Alabama)" },
      { id: "2004223", name: "Jessica Gros (Alabama)" },
      { id: "2000867", name: "Dennis Pritchett Jr" },
    ],
      Florida: [
        // SRO Operators
        { id: "JE313023", name: "Brian Todd Waddle", location: "SRO" },
        { id: "JE221227", name: "Elijamir Dasilva", location: "SRO" },
        { id: "JE307918", name: "Encarnacion Ramon-Son", location: "SRO" },
        { id: "JE192032", name: "Tammy Williford", location: "SRO" },
        { id: "JE282437", name: "Tracy L. Peele", location: "SRO" },
        { id: "JE300552", name: "Raymundo Barbosa Barajas", location: "SRO" },
        { id: "JE300565", name: "Oscar Padilla Saldana", location: "SRO" },
        { id: "JE314570", name: "Luis Igancio Plascencia Morales", location: "SRO" },
        { id: "JE319723", name: "Cesar Hugo Padilla-Saldana", location: "SRO" },
        { id: "JE319713", name: "Alberto Aldama-Guerra", location: "SRO" },
        { id: "JE319136", name: "Christian Jackson", location: "SRO" },
        { id: "JE319137", name: "Ashton Martin", location: "SRO" },
        { id: "JE319133", name: "John Borja", location: "SRO" },
        { id: "JE327071", name: "Angel Merino Sanchez", location: "SRO" },
        { id: "JE337783", name: "Nathan Miles Taylor", location: "SRO" },
        { id: "JE337784", name: "Jose Banda Vega", location: "SRO" },
        { id: "JE337780", name: "Raul Rios Reyes", location: "SRO" },
        { id: "JE347835", name: "Mihael Lewis", location: "SRO" },
        { id: "JE347832", name: "Erik Frederick", location: "SRO" },
        { id: "JE330239", name: "Danielle Kathleen Burch", location: "SRO" },
        { id: "JE347834", name: "Kolby Gordon", location: "SRO" },
        { id: "JE245474", name: "Christopher Lee Peppers", location: "SRO" },
        { id: "JE358828", name: "Jacob Alexander Edelman", location: "SRO" },
        { id: "JB279027", name: "RCI SRO Office", location: "SRO" }, // New Entry
    
        // Apopka Operators
        { id: "JE360496", name: "Danilo Castillo Rodriguez", location: "APOPKA" },
        { id: "JE360497", name: "Risarui Akberti Bojorque-Soto", location: "APOPKA" },
        { id: "JE360498", name: "Rodolfo Jimenez", location: "APOPKA" },
        { id: "JE360499", name: "Nelson Dort", location: "APOPKA" },
        { id: "JE360500", name: "Victor Manuel Lopez-Mora", location: "APOPKA" },
        { id: "JE360501", name: "Wikenson Ciceron", location: "APOPKA" },
        { id: "JE360502", name: "Mario Eduardo Rubio Valdez", location: "APOPKA" },
        { id: "JE360503", name: "Yeandry Batista Ramirez", location: "APOPKA" },
        { id: "JE360504", name: "Anthony D. Scott", location: "APOPKA" },
        { id: "JE360505", name: "Alvin Coleman", location: "APOPKA" },
        { id: "JE360506", name: "Cesar Guerra Penate", location: "APOPKA" },
        { id: "JE360507", name: "Joel Gonzales Socarrde", location: "APOPKA" },
        { id: "JE315698", name: "Jose Coca Sanchez", location: "APOPKA" },
        { id: "JE360495", name: "Eiser Ramirez Guerra", location: "APOPKA" },
        { id: "JE83762", name: "William G Sandritter", location: "APOPKA" },
        { id: "JE360509", name: "Roberto Delgado Garcia", location: "APOPKA" },
        { id: "JE147264", name: "Lorenzo Wright Jr", location: "APOPKA" },
        { id: "JE360497", name: "Rosario Alberto Bojorquez-Soto", location: "APOPKA" },
        { id: "JE295247", name: "John Neuber", location: "APOPKA" },
        { id: "JE361267", name: "Giovanni Keron Forrest", location: "APOPKA" },
        { id: "JE361268", name: "Gabriel Munoz", location: "APOPKA" },
        { id: "JE361269", name: "Omar Eastellanos", location: "APOPKA" },
        { id: "JE361270", name: "Ezekiel Titus Davis", location: "APOPKA" },
        { id: "JE361271", name: "Carol Orlando Zackery", location: "APOPKA" },
        { id: "JE314244", name: "Jonathan Alvelo", location: "APOPKA" },
        { id: "JB359443", name: "RCI Apopka Office", location: "APOPKA" }, // New Entry
    
        // PCB Operators
        { id: "JE329657", name: "Antonio Cervantes Galvan", location: "PCB" },
        { id: "JE329653", name: "Evon Baker", location: "PCB" },
        { id: "JE329654", name: "Francisco Lozano Carreon", location: "PCB" },
        { id: "JE329655", name: "Irving Valle Castro", location: "PCB" },
        { id: "JE331315", name: "Jose Daniel-Perez Garcia", location: "PCB" },
        { id: "JE331317", name: "Jesus Alexis- Alvarez Berrellez", location: "PCB" },
        { id: "JE331318", name: "Joel Lugo Villanueva", location: "PCB" },
        { id: "JE331319", name: "Ismael Osorio Perez", location: "PCB" },
        { id: "JE331324", name: "Cruz Prudencio Marco Antonio", location: "PCB" },
        { id: "JE331327", name: "Luis Gerardo Serrano Andrade", location: "PCB" },
        { id: "JE331328", name: "Eduardo Ibarra Hernandez", location: "PCB" },
        { id: "JE331330", name: "Jose Felix Barajas Florido", location: "PCB" },
        { id: "JE331331", name: "Vicente Barajas Florido", location: "PCB" },
        { id: "JE331332", name: "Jose Cruz Pardo", location: "PCB" },
        { id: "JB326363", name: "RCI PCB Office", location: "PCB" }, // New Entry
    
        // Agricultural Dealer License (No Specific Location)
        { id: "AD1763", name: "Agricultural Dealer License" },
        { id: "JB326363", name: "RCI PCB Office" },
        { id: "JB359443", name: "RCI SRO Office" },
        { id: "JB359443", name: "RCI SRO Office" },
         // New Entry
      ],
  
    
    Georgia: [{ id: "4347", name: "Brandon Stegall" }],

    Louisiana: [
      { id: "002D7N", name: "Charles Hollingsworth(Louisiana)" },
      { id: "003JZX", name: "Jessica Gros(Louisiana)" },
      { id: "003K10", name: "Bryan Martin" },
      { id: "003K01", name: "John Borja(Louisiana)" },
      { id: "0045A5", name: "Ashton Martin(Louisiana)" },
      { id: "003JZG", name: "Megan Olden" },
      { id: "003M5H", name: "Bill Macmurdo" },
      { id: "003K1A", name: "Russell S Harris III" },
      { id: "0040AG", name: "Russell B Watson" },
    ],

    Mississippi: [
      { id: "CA17420", name: "Ashton Martin(Mississippi)" },
      { id: "CA17419", name: "Charles Hollingsworth(Mississippi)" },
      { id: "CA17407", name: "John Andrew Borja(Mississippi)" },
    ],
    Texas: [
      { id: "861041", name: "James Gravett" },
      { id: "922081", name: "Zackery Kidd" },
      { id: "806734", name: "Charles Hollingsworth(Texas)" },
      { id: "876063", name: "Jessica Gros(Texas)" },
    ],
  };

  const navigateBasedOnRole = (role) => {
    const dashboardRoutes = {
      spray_tech: '/spray-tech-dashboard',
      spray_manager: '/spray-manager-dash',
      supervisor: '/supervisor-dashboard',
      manager: '/manager-dashboard',
      admin: '/admin-dash',
    };
  
    const route = dashboardRoutes[role] || '/unauthorized';
    navigate(route);
  };
  
  

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {/* Address, City, and State Section */}
      <h2>Location</h2>
      <Input
        name="address"
        placeholder="Address"
        value={newRecord.address}
        onChange={handleInputChange}
      />
      <Input
        name="city"
        placeholder="City"
        value={newRecord.city}
        onChange={handleInputChange}
      />
      <Input
        name="state"
        placeholder="State"
        value={newRecord.state}
        onChange={handleInputChange}
      />
        <h3>Chemical Application Details</h3>
        
        <Input
          name="applicator"
          placeholder="Applicator Name"
          value={newRecord.applicator}
          onChange={handleInputChange}
        />

        <Input
  list="operatorList"
  name="ownerOperatorNumber"
  placeholder="Select or enter Operator ID"
  value={newRecord.ownerOperatorNumber}
  onChange={(e) => setNewRecord({ ...newRecord, ownerOperatorNumber: e.target.value })}
  required
/>

<datalist id="operatorList">
  {Object.entries(operators).map(([state, ops]) => (
    <React.Fragment key={state}>
      {ops.map((op) => (
        <option key={op.id} value={op.id}>
          {op.id} - {op.name} {op.location ? `(${op.location})` : ""}
        </option>
      ))}
    </React.Fragment>
  ))}
</datalist>



        <Input
          name="project"
          placeholder="Project Name"
          value={newRecord.project}
          onChange={handleInputChange}
        />
        <Input
          name="applicationDate"
          type="date"
          value={newRecord.applicationDate}
          onChange={handleInputChange}
        />
        <Input
          name="temperature"
          placeholder="Temperature (°F)"
          value={newRecord.temperature}
          onChange={handleInputChange}
        />
        <Input
          name="wind"
          placeholder="Wind (mph)"
          value={newRecord.wind}
          onChange={handleInputChange}
        />
     <div>   
        <Grid>
    <Input
      type="text"
      name="areaTreated"
      placeholder="Enter size (e.g., 5)"
      value={newRecord.areaTreated}
      onChange={handleInputChange}
      required
    />
    <Select
      name="areaUnit"
      value={newRecord.areaUnit}
      onChange={handleInputChange}
      required
    >
      <option value="">Select Unit</option>
      <option value="acres">Acres</option>
      <option value="sqft">Square Feet</option>
      <option value="hectares">Hectares</option>
      <option value="meters">Square Meters</option>
    </Select>
  </Grid>
</div>
        <div>
  <label style={{ fontWeight: 'bold' }}>
    <span style={{ color: 'red' }}>*</span> Used restricted general use pesticide?
  </label>
  <CheckboxContainer>
    <Checkbox>
      <input
        type="radio"
        name="usedRestrictedPesticide"
        value="Yes"
        checked={newRecord.usedRestrictedPesticide === 'Yes'}
        onChange={handleInputChange}
        required
      />
      Yes
    </Checkbox>
    <Checkbox>
      <input
        type="radio"
        name="usedRestrictedPesticide"
        value="No"
        checked={newRecord.usedRestrictedPesticide === 'No'}
        onChange={handleInputChange}
        required
      />
      No
    </Checkbox>
  </CheckboxContainer>
</div>

        <CheckboxContainer>
          {checkboxLabels.map((checkbox) => (
            <Checkbox key={checkbox.name}>
              <input
                type="checkbox"
                name={checkbox.name}
                checked={newRecord.checkboxes[checkbox.name]}
                onChange={handleCheckboxChange}
              />
              {checkbox.label}
            </Checkbox>
          ))}
        </CheckboxContainer>
        {newRecord.chemicals.map((chemical, index) => (
          <div key={index}>
            <h3>Chemical {index + 1}</h3>
            <Select
              name="category"
              value={chemical.category}
              onChange={(e) => handleChemicalChange(index, e)}
            >
              <option value="">Select Category</option>
              {Object.keys(chemicals).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </Select>
            <Select
              name="product"
              value={chemical.product}
              onChange={(e) => handleChemicalChange(index, e)}
              disabled={!chemical.category}
            >
              <option value="">Select Product</option>
              {chemical.category &&
                chemicals[chemical.category]?.map((product) => (
                  <option key={product} value={product}>
                    {product}
                  </option>
                ))}
            </Select>
            <Grid>
              <Input
                name="epaNumber"
                placeholder="EPA Number"
                value={chemical.epaNumber}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="lotNumber"
                placeholder="Lot Number"
                value={chemical.lotNumber}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="rate"
                placeholder="Rate"
                value={chemical.rate}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="per"
                placeholder="Per"
                value={chemical.per}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="totalApplied"
                placeholder="Total Applied"
                value={chemical.totalApplied}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="totalConc"
                placeholder="Total Concentration"
                value={chemical.totalConc}
                onChange={(e) => handleChemicalChange(index, e)}
              />
            </Grid>
          </div>
        ))}
        <Input
          name="notes"
          placeholder="Notes"
          value={newRecord.notes}
          onChange={handleInputChange}
        />
        <ButtonContainer>
        <Button type="button" onClick={handlePDFPreview}>
          Preview PDF
        </Button>
        <Button type="submit">
          Submit
        </Button>


      </ButtonContainer>
    </Form>
    {showPDF && (
        <PDFContainer>
          <PDFViewer width="100%" height="100%">
            <ChemicalApplicationPDF recordData={newRecord} />
          </PDFViewer>
          <CloseButton onClick={handlePDFClose}>Close</CloseButton>
        </PDFContainer>
      )}
      {successModalVisible && (
        <ModalOverlay>
          <ModalContent>
            <h2>Submission Successful!</h2>
            <p>Your record has been successfully updated.</p>
            <Button onClick={handleCloseModal}>Close</Button>
          </ModalContent>
        </ModalOverlay>
      )}

{showEmailModal && (
  <ModalOverlay>
    <ModalContent>
      <h2>Enter Recipient Email</h2>
      <Input
        type="text"
        placeholder="Enter email(s), separated by commas"
        value={manualEmail}
        onChange={(e) => setManualEmail(e.target.value)}
      />
      <Button onClick={async () => {
        try {
          await handleNotifyReviewNeeded(pendingFormData);
          setShowEmailModal(false);
          setSuccessModalVisible(true);
          setManualEmail('');

        } catch {
          alert('Failed to send email.');
        }
      }}>
        Send Email
      </Button>
      <Button onClick={() => setShowEmailModal(false)}>Cancel</Button>
    </ModalContent>
  </ModalOverlay>
)}

    </>
    
  );
};

export default TechnicianDashboard;