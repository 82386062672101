import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const DashboardContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #219a52;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
    font-size: 16px;
  }
`;

const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const WeatherWidget = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StatusTitle = styled.h3`
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const StatusInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Modal = styled.div`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  max-width: 800px;
  background: white;
  padding: 20px;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const StatusCard = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const SprayTechDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchWeatherData = async (latitude, longitude) => {
      const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
      
      if (!apiKey) {
        console.error('Weather API key is not configured');
        setError('Weather service is not configured properly.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${latitude},${longitude}&aqi=yes`
        );
        
        const { current, location } = response.data;
        setWeatherData({
          temperature: current.temp_f,
          humidity: current.humidity,
          windSpeed: current.wind_mph,
          windDirection: current.wind_dir,
          location: location.name,
          condition: current.condition.text,
          feelsLike: current.feelslike_f,
          uvIndex: current.uv,
          precipitation: current.precip_in,
          visibility: current.vis_miles,
          pressure: current.pressure_mb,
        });
        setLoading(false);
      } catch (err) {
        console.error('Weather API Error:', err);
        setError(err.response?.data?.error?.message || 'Unable to fetch weather data.');
        setLoading(false);
      }
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchWeatherData(latitude, longitude);
          },
          (err) => {
            console.error('Geolocation Error:', err);
            setError('Geolocation permission denied. Please enable location services.');
            setLoading(false);
          }
        );
      } else {
        setError('Geolocation is not supported by your browser.');
        setLoading(false);
      }
    };

    getLocation();

    // Refresh weather data every 15 minutes
    const refreshInterval = setInterval(getLocation, 900000);

    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <DashboardContainer>
      <StyledTitle>{t('Spray Tech Dashboard')}</StyledTitle>

      <WeatherWidget>
        <StatusTitle>{t('Current Weather Conditions')}</StatusTitle>
        {loading ? (
          <p>{t('Loading weather data...')}</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{t(error)}</p>
        ) : weatherData && (
          <>
            <StatusInfo>
              <span>{t('Location')}</span>
              <span>{weatherData.location}</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Condition')}</span>
              <span>{weatherData.condition}</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Temperature')}</span>
              <span>{weatherData.temperature}°F</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Feels Like')}</span>
              <span>{weatherData.feelsLike}°F</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Wind')}</span>
              <span>{weatherData.windSpeed} mph {weatherData.windDirection}</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Humidity')}</span>
              <span>{weatherData.humidity}%</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('UV Index')}</span>
              <span>{weatherData.uvIndex}</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Precipitation')}</span>
              <span>{weatherData.precipitation} in</span>
            </StatusInfo>
            <StatusInfo>
              <span>{t('Visibility')}</span>
              <span>{weatherData.visibility} miles</span>
            </StatusInfo>
          </>
        )}
      </WeatherWidget>

      <StatusCard>
        <StatusTitle>{t('Today\'s Schedule')}</StatusTitle>
        <StatusInfo>
          <span>{t('Pending Applications')}</span>
          <span>3</span>
        </StatusInfo>
        <StatusInfo>
          <span>{t('Completed Applications')}</span>
          <span>2</span>
        </StatusInfo>
      </StatusCard>

      <ButtonContainer>
        <StyledButton type="button" onClick={() => navigate('/chem-app-form')}>
          {t('Start New Application')}
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/incident-report')}>
          {t('Incident Report Form')}
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/pending-forms')}>
          {t('Pending Applications')}
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/supervisor-trip-inspection')}>
          {t('Truck Inpsection')}
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/inspection-history')}>
          {t('Inspection History')}
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/application-history')}>
          {t('View Application History')}
        </StyledButton>
        <StyledButton type="button" onClick={() => navigate('/saved-reports')}>
          {t('Saved Forms')}
        </StyledButton>
      </ButtonContainer>

      <Modal isOpen={isModalOpen} onClick={toggleModal}>
        <VideoContainer onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={toggleModal}>✖</CloseButton>
          <iframe
            src="https://www.youtube.com/embed/placeholder-safety-video"
            title="Safety Tutorial Video"
            frameBorder="0"
            allowFullScreen
          />
        </VideoContainer>
      </Modal>
    </DashboardContainer>
  );
};

export default SprayTechDashboard;