import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const StyledTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Single column for small screens */
  }
`;

const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  width: 220px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;

  &:hover {
    background-color: #219653;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 180px;
    height: 50px;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    width: 100%; /* Full width for small screens */
    height: 50px;
    font-size: 0.8rem;
  }
`;

const AdminDashboard = () => {
  const navigate = useNavigate();

  const dashboards = [
    { label: 'User Management', path: '/user-management' },
    { label: 'Spray Tech Manager Dashboard', path: '/spray-manager-dash' },
    { label: 'Manager Dashboard', path: '/manager-dashboard' },
    { label: 'Supervisor Dashboard', path: '/supervisor-dashboard' },
    { label: 'Spray Tech Dashboard', path: '/spray-tech-dashboard' },
    { label: 'All Services Updates', path: '/admin-job-list' },
    { label: 'Open Punch Lists', path: '/admin-punch-list' },
    { label: 'Marketing Images', path: '/image-gallery' }, 
    {
      label: 'Submitted Chemical Applications',
      onClick: () =>
        window.open(
          'https://rotoloconsultants.quickbase.com/nav/app/brspiu9w6/table/brt8jnu22/action/td',
          '_blank'
        ),
    },
    { label: 'Bid Proposal', path: '/bid-proposal' },
    { label: "Vehicle Inspection", path: "/all-inpsections" },
  ];

  return (
    <Container>
      <StyledTitle>Admin Dashboard</StyledTitle>
      <ButtonContainer>
        {dashboards.map((dashboard, index) => (
          <StyledButton
            key={index}
            onClick={() =>
              dashboard.onClick
                ? dashboard.onClick()
                : navigate(dashboard.path)
            }
          >
            {dashboard.label}
          </StyledButton>
        ))}
      </ButtonContainer>
    </Container>
  );
};

export default AdminDashboard;
