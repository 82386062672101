import React, { useState, useEffect } from "react";
import { format, differenceInMinutes, startOfWeek, addDays } from "date-fns";
import * as XLSX from "xlsx";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Firebase Functions/firebase";
import { useLocation } from "react-router-dom";
import "./WeeklyReport.css";

const WeeklyReport = () => {
  const location = useLocation();
  const [userInspectionCounts, setUserInspectionCounts] = useState({});
  const userList = location.state?.userList || [];
  const divisionCountsFromUsers = location.state?.divisionCountsFromUsers || {};
  const [weeklyReport, setWeeklyReport] = useState(null);
  const [avgTimeToSubmit, setAvgTimeToSubmit] = useState(null);
  const [avgTimeToSend, setAvgTimeToSend] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userNames, setUserNames] = useState({});
  const [error, setError] = useState("");

 



  useEffect(() => {
    console.log("🧾 Received userList:", userList);
    console.log("🧾 Received divisionCountsFromUsers:", divisionCountsFromUsers);
  }, []);

  useEffect(() => {
    generateWeeklyReport();
  }, []);

  const fetchUserNames = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "Users"));
      const nameMap = {};

      usersSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.email) {
          const normalizedEmail = data.email.trim().toLowerCase();
          nameMap[normalizedEmail] = `${data.first_name || "Unknown"} ${data.last_name || "User"}`;
        }
      });

      setUserNames(nameMap);
    } catch (error) {
      console.error("❌ Error fetching user names:", error);
    }
  };

  const formatTime = (minutes) => {
    if (minutes === "N/A") return "N/A";
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    return `${days} days, ${hours} hours`;
  };

  const fetchServiceTimes = async () => {
    try {
      const servicesSnapshot = await getDocs(collection(db, "Services"));
      let totalSubmitTime = 0;
      let totalSendTime = 0;
      let countSubmit = 0;
      let countSend = 0;

      servicesSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.date && data.createdAt) {
          const performedDate = data.date.toDate ? data.date.toDate() : new Date(data.date);
          const createdAt = data.createdAt.toDate ? data.createdAt.toDate() : new Date(data.createdAt);
          const submitTime = differenceInMinutes(createdAt, performedDate);
          totalSubmitTime += submitTime;
          countSubmit++;
        }
        

        if (data.createdAt && data.sentToQuickbase) {
          const createdAt = data.createdAt.toDate ? data.createdAt.toDate() : new Date(data.createdAt);
          const sentTime = data.sentToQuickbase.toDate ? data.sentToQuickbase.toDate() : new Date(data.sentToQuickbase);
          const sendTime = differenceInMinutes(sentTime, createdAt);
          totalSendTime += sendTime;
          countSend++;
        }
        
      });

      setAvgTimeToSubmit(countSubmit ? formatTime(totalSubmitTime / countSubmit) : "N/A");
      setAvgTimeToSend(countSend ? formatTime(totalSendTime / countSend) : "N/A");

    } catch (error) {
      console.error("❌ Error fetching service times:", error);
    }
  };

  const generateWeeklyReport = async () => {
    setLoading(true);
    setError("");

    try {
      console.log("🚀 Calling Cloud Function...");
      const response = await fetch(
        "https://us-central1-landcsape-app.cloudfunctions.net/aggregateWeeklyReport",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("✅ Cloud Function Response:", data);

      if (!data || !data.success) {
        throw new Error("Invalid response from server.");
      }
      setUserInspectionCounts(data.userInspectionCounts || {});

      const weekStartDate = startOfWeek(new Date(), { weekStartsOn: 1 }); // Monday
      const weekEndDate = addDays(weekStartDate, 6); // Sunday

      setWeeklyReport({
        weekStart: format(weekStartDate, "yyyy-MM-dd"),
        weekEnd: format(weekEndDate, "yyyy-MM-dd"),
        updatesCount: data.servicesCount ?? 0,
        activeManagers: data.activeManagers || [],
        activeSupervisors: data.activeSupervisors || [],
        inactiveManagers: data.inactiveManagers || [],
        inactiveSupervisors: data.inactiveSupervisors || [],
        inspectionsCount: data.inspectionsCount || { preTrip: 0, postTrip: 0 },
        divisionCounts: data.divisionCounts || {},
        sentOutCount: data.sentOutCount ?? 0,
        servicesWithDate: data.servicesWithDate ?? 0, // 🔹 New
        servicesWithCreatedAt: data.servicesWithCreatedAt ?? 0, // 🔹 New
        servicesWithSentToQuickbase: data.servicesWithSentToQuickbase ?? 0,
        userUpdateCounts: data.userUpdateCounts ? data.userUpdateCounts : {},
        divisionServiceStatusCounts: data.divisionServiceStatusCounts || {} 
      });

      await fetchUserNames();
      await fetchServiceTimes();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("An error occurred while generating the report.");
      console.error("❌ Error:", error);
    }
  };

  const downloadExcelReport = (userList = [], divisionCountsFromUsers = {}) => {
    if (!weeklyReport) {
      alert("No report data to download.");
      return;
    }
  
    // --- Weekly Report Sheet ---
    const headers = [
      ["Division", "Updates", "Services Done", "Waiting for Approval", "Sent to Clients"]
    ];
  
    const rows = Object.entries(weeklyReport.divisionCounts).map(([division, updates]) => {
      const status = weeklyReport.divisionServiceStatusCounts?.[division] || {};
      return [
        division,
        updates,
        status.done ?? 0,
        status.waitingApproval ?? 0,
        status.sentToClient ?? 0
      ];
    });
  
    const totals = [
      [],
      ["Total Services Done This Week", weeklyReport.servicesWithDate ?? 0],
      ["Total Services Waiting For Approval", weeklyReport.servicesWithCreatedAt ?? 0],
      ["Total Services Sent to Quickbase", weeklyReport.servicesWithSentToQuickbase ?? 0],
      ["Total Updates Done", weeklyReport.updatesCount ?? 0]
    ];
  
    const weeklySheetData = [...headers, ...rows, ...totals];
    const weeklySheet = XLSX.utils.aoa_to_sheet(weeklySheetData);
  
    // --- User Management Sheet ---
    const userHeaders = [
      ["First Name", "Last Name", "Email", "Role", "Last Login", "Login Count", "Pre-Trip Inspections", "Post-Trip Inspections"]
    ];
    
    const userRows = userList.map(user => {
      const normalizedEmail = user.email?.trim().toLowerCase();
      const inspectionData = userInspectionCounts?.[normalizedEmail] || {};
      
      return [
        user.first_name || "",
        user.last_name || "",
        user.email || "",
        user.type || "",
        user.last_login ? new Date(user.last_login).toLocaleString() : "N/A",
        user.login_count ?? 0,
        inspectionData.preTrip ?? 0,
        inspectionData.postTrip ?? 0
      ];
    });
    
  
    const userSheetData = [...userHeaders, ...userRows];
  
    // Add Division Summary to bottom of user sheet
    userSheetData.push([], ["Division Update Summary"]);
    Object.entries(divisionCountsFromUsers).forEach(([division, count]) => {
      userSheetData.push([division, count]);
    });
  
    const userSheet = XLSX.utils.aoa_to_sheet(userSheetData);
  
    // --- Create Workbook and Append Sheets ---
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, weeklySheet, "Weekly Report");
    XLSX.utils.book_append_sheet(workbook, userSheet, "User Management");
  
    XLSX.writeFile(workbook, `weekly-report-${weeklyReport.weekStart}.xlsx`);
  };
  
  


  return (
    <div className="report-container">
      <div className="report-card">
        <h1 className="report-title">Weekly Report</h1>

        {loading ? (
          <p className="loading-text">Loading report...</p>
        ) : error ? (
          <p className="error-text">{error}</p>
        ) : (
          <div className="report-content">
            <h2>Week: {weeklyReport?.weekStart || "N/A"} to {weeklyReport?.weekEnd || "N/A"}</h2>

            {/* Display average times in days and hours */}
            
              {/*<p><strong>Avg. Time to Submit Update:</strong> {avgTimeToSubmit}</p>*/}
              {/*<p><strong>Avg. Time to Send Update:</strong> {avgTimeToSend}</p>*/}
           

           

            <div className="report-section">
              <h3>Service Updates by Division</h3>
              <table>
                <thead>
                <tr>
                  <th>Division</th>
                  <th>Updates</th>
                  <th>Services Done</th>
                  <th>Waiting for Approval</th>
                  <th>Sent to Clients</th>
                </tr>
                </thead>
                <tbody>
    {weeklyReport?.divisionCounts && Object.keys(weeklyReport.divisionCounts).length > 0 ? (
      Object.entries(weeklyReport.divisionCounts).map(([division, count]) => {
        const status = weeklyReport.divisionServiceStatusCounts?.[division] || {};
        return (
          <tr key={division}>
            <td>{division}</td>
            <td>{count}</td>
            <td>{status.done ?? 0}</td>
            <td>{status.waitingApproval ?? 0}</td>
            <td>{status.sentToClient ?? 0}</td>
          </tr>
        );
      })
    ) : (
      <tr><td colSpan="5">No service updates recorded per division.</td></tr>
    )}
  </tbody>
                <tfoot>
      
    
      <tr>
    <td>Total Services Done This Week:</td>
    <td>{weeklyReport?.servicesWithDate ?? 0}</td>
  </tr>
  <tr>
    <td>Total Services Waiting For Approval:</td>
    <td>{weeklyReport?.servicesWithCreatedAt ?? 0}</td>
  </tr>
  <tr>
    <td>Total Services Sent to Quickbase:</td>
    <td>{weeklyReport?.servicesWithSentToQuickbase ?? 0}</td>
  </tr>
  
  <tr>
        <td><strong>Total Updates Done:</strong></td>
        <td>{weeklyReport?.updatesCount ?? "N/A"}</td>
      </tr>
</tfoot>
              </table>
            </div>

            <div className="report-section">
              <h3>Inspection Reports</h3>
              <p><strong>Pre-trip Inspections:</strong> {weeklyReport?.inspectionsCount?.preTrip ?? 0}</p>
              <p><strong>Post-trip Inspections:</strong> {weeklyReport?.inspectionsCount?.postTrip ?? 0}</p>
            </div>

            <div className="report-section">
              <h3>User Activity</h3>
              <p><strong>Active Managers:</strong> {weeklyReport?.activeManagers.length}</p>
              <p><strong>Inactive Managers:</strong> {weeklyReport?.inactiveManagers.length}</p>
              <p><strong>Active Supervisors:</strong> {weeklyReport?.activeSupervisors.length}</p>
              <p><strong>Inactive Supervisors:</strong> {weeklyReport?.inactiveSupervisors.length}</p>
            </div>

            <button className="download-button" onClick={() => downloadExcelReport(userList, divisionCountsFromUsers)}>
                               Download Excel Report
            </button>




          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyReport;
