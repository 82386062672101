import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#FAFAF7',
    fontSize: 12,
    color: '#37474F',
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: 'center',
    color: '#2F5E1E',
  },
  section: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 14,
    color: '#1B5E20',
    fontWeight: 'bold',
    marginBottom: 6,
    borderBottom: '1px solid #4CAF50',
    paddingBottom: 2,
  },
  field: {
    marginBottom: 4,
  },
  label: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
  },
});

const Field = ({ label, value }) => (
  <View style={styles.field}>
    <Text>
      <Text style={styles.label}>{label}: </Text>
      <Text>{value || 'N/A'}</Text>
    </Text>
  </View>
);

const IncidentReportPDF = ({ reportData }) => {
  const formatDate = (dateStr) =>
    dateStr ? format(parseISO(dateStr), 'MM/dd/yyyy') : 'N/A';

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Incident Report</Text>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Employee Information</Text>
          <Field label="Employee Name" value={reportData.employeeName} />
          <Field label="Phone" value={reportData.employeePhone} />
          <Field label="Job Title" value={reportData.jobTitle} />
          <Field label="Supervisor" value={reportData.supervisor?.name} />
          <Field label="Date of Incident" value={formatDate(reportData.incidentDate)} />
          <Field label="Time of Incident" value={reportData.incidentTime} />
          <Field label="Location" value={reportData.location} />
          <Field label="Truck Involved" value={reportData.truckNumber} />
          <Field label="Attached Photos?" value={reportData.attachedPictures === true ? 'Yes' : 'No'} />
          <Field label="Release Form on File?" value={reportData.releaseForm === true ? 'Yes' : 'No'} />
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Occurrence Details</Text>
          <Field label="Description of What Happened" value={reportData.whatHappened} />
          <Field label="Corrective Action Taken" value={reportData.correctiveAction} />
          <Field label="Was This Due to Employee Negligence?" value={reportData.negligence ? 'Yes' : 'No'} />
          <Field label="Employee Deduction?" value={reportData.employeeDeduction ? 'Yes' : 'No'} />
          <Field label="Reason if No Deduction" value={reportData.deductionReason} />
          <Field label="Were There Witnesses?" value={reportData.witnesses ? 'Yes' : 'No'} />
          <Field label="Damaged Vehicle (Year/Make/Model)" value={reportData.vehicleDescription} />
          <Field label="Claimant Name & Phone" value={reportData.claimantContact} />
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Medical Details</Text>
          <Field label="Accompanied to Hospital/Clinic?" value={reportData.accompanied ? 'Yes' : 'No'} />
          <Field label="Initial Treatment?" value={reportData.initialTreatment ? 'Yes' : 'No'} />
          <Field label="Denied Treatment?" value={reportData.deniedTreatment ? 'Yes' : 'No'} />
          <Field label="Minor Clinic?" value={reportData.minorClinic ? 'Yes' : 'No'} />
          <Field label="Emergency?" value={reportData.emergency ? 'Yes' : 'No'} />
        </View>
      </Page>
    </Document>
  );
};

export default IncidentReportPDF;
