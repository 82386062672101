import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';
import QuickbaseUpdate from '../Quickbase Functions/QBChemForm';
import { pdf } from '@react-pdf/renderer';
import { ChemicalApplicationPDF } from '../PDF Functions/ChemAppPDF';

const sectionStyle = {
  border: '1px solid #ddd',
  padding: '1rem',
  borderRadius: '6px',
  backgroundColor: '#f9f9f9',
  marginBottom: '1.5rem',
};

const Button = {
  backgroundColor: '#27ae60',
  color: 'white',
  padding: '0.6rem 1.2rem',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '1rem',
  marginTop: '1rem',
};

const labelStyle = {
  fontWeight: 'bold',
  display: 'block',
  marginTop: '0.5rem',
};

const valueStyle = {
  marginBottom: '0.5rem',
};

const ChemicalReviewPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const docRef = doc(db, 'ChemAppForms', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFormData(docSnap.data());
        } else {
          console.error('❌ No document found for this ID');
        }
      } catch (error) {
        console.error('❌ Error fetching document:', error);
      }
    };

    fetchForm();
  }, [id]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const handleApproveAndPush = async () => {
    try {
      const blob = await pdf(<ChemicalApplicationPDF recordData={formData} />).toBlob();
      const pdfBase64 = await convertBlobToBase64(blob);

      const fields = {
        applicator: formData.applicator,
        projectName: formData.project,
        applicationDate: formData.applicationDate,
        notes: formData.notes,
        pdfBase64: pdfBase64,
      };

      const result = await QuickbaseUpdate({
        tableId: 'brt8jnu22', // Replace with actual table ID
        fields,
      });

      console.log("✅ Pushed to Quickbase:", result);

      await updateDoc(doc(db, 'ChemAppForms', id), { status: 'approved' });
      alert('✅ Approved and pushed to Quickbase!');
    } catch (error) {
      console.error("❌ Quickbase push failed:", error);
      alert('Error occurred while pushing to Quickbase.');
    }
  };

  if (!formData) return <p>Loading...</p>;

  return (
    <div style={{ padding: '2rem', maxWidth: '800px', margin: '0 auto' }}>
      <h2>Chemical Application Review</h2>

      <div style={sectionStyle}>
        <h3>General Info</h3>
        <p><span style={labelStyle}>Project:</span> <span style={valueStyle}>{formData.project}</span></p>
        <p><span style={labelStyle}>Applicator:</span> {formData.applicator}</p>
        <p><span style={labelStyle}>Applicator ID:</span> {formData.applicatorId}</p>
        <p><span style={labelStyle}>Owner/Operator Number:</span> {formData.ownerOperatorNumber}</p>
        <p><span style={labelStyle}>Application Date:</span> {formData.applicationDate}</p>
      </div>

      <div style={sectionStyle}>
        <h3>Location</h3>
        <p><span style={labelStyle}>Address:</span> {formData.address}</p>
        <p><span style={labelStyle}>City, State:</span> {formData.city}, {formData.state}</p>
      </div>

      <div style={sectionStyle}>
        <h3>Conditions</h3>
        <p><span style={labelStyle}>Temperature:</span> {formData.temperature} °F</p>
        <p><span style={labelStyle}>Wind:</span> {formData.wind} mph</p>
        <p><span style={labelStyle}>Area Treated:</span> {formData.areaTreated} {formData.areaUnit}</p>
        <p><span style={labelStyle}>Used Restricted Pesticide:</span> {formData.usedRestrictedPesticide}</p>
      </div>

      <div style={sectionStyle}>
        <h3>Application Methods</h3>
        <ul>
          {Object.entries(formData.checkboxes || {}).map(([label, checked]) =>
            checked ? <li key={label}>{label.replace(/([A-Z])/g, ' $1')}</li> : null
          )}
        </ul>
      </div>

      <div style={sectionStyle}>
        <h3>Chemicals Applied</h3>
        {formData.chemicals?.map((chem, index) => {
          const hasAnyValue = Object.values(chem).some(value => value && value.trim() !== '');
          if (!hasAnyValue) return null;

          return (
            <div key={index} style={{ marginBottom: '1rem', paddingBottom: '1rem', borderBottom: '1px dashed #ccc' }}>
              <p><strong>Chemical {index + 1}</strong></p>
              {chem.category && <p><strong>Category:</strong> {chem.category}</p>}
              {chem.product && <p><strong>Product:</strong> {chem.product}</p>}
              {chem.epaNumber && <p><strong>EPA #:</strong> {chem.epaNumber}</p>}
              {chem.lotNumber && <p><strong>Lot #:</strong> {chem.lotNumber}</p>}
              {chem.rate && <p><strong>Rate:</strong> {chem.rate}</p>}
              {chem.per && <p><strong>Per:</strong> {chem.per}</p>}
              {chem.totalApplied && <p><strong>Total Applied:</strong> {chem.totalApplied}</p>}
              {chem.totalConc && <p><strong>Total Concentration:</strong> {chem.totalConc}</p>}
            </div>
          );
        })}
      </div>

      <div style={sectionStyle}>
        <h3>Notes</h3>
        <p>{formData.notes || 'None provided'}</p>
      </div>

      <button style={Button} onClick={handleApproveAndPush}>
        Approve and Push to Quickbase
      </button>
    </div>
  );
};

export default ChemicalReviewPage;
