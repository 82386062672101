import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../Firebase Functions/firebase";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from "firebase/firestore";
import "./UserManagement.css";


const UserManagement = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [divisionCounts, setDivisionCounts] = useState({});
  const [managerCount, setManagerCount] = useState(0);
  const [supervisorCount, setSupervisorCount] = useState(0);
  const [managerData, setManagerData] = useState([]);
  const [userLoginData, setUserLoginData] = useState(null);
  const [supervisorData, setSupervisorData] = useState([]);
  const [loginActivityData, setLoginActivityData] = useState([]);
  const isUserDataLoaded = users.length > 0 && Object.keys(divisionCounts).length > 0;

  

  // Group users by creation date and role
  const groupByDateAndType = (users) => {
    const dateCounts = {};

    users.forEach((user) => {
      const createdAt = user.created_at ? new Date(user.created_at.seconds * 1000) : null;
      if (createdAt) {
        const dateKey = createdAt.toISOString().split("T")[0];

        if (!dateCounts[dateKey]) {
          dateCounts[dateKey] = { manager: 0, supervisor: 0 };
        }

        if (user.type === "manager") {
          dateCounts[dateKey].manager += 1;
        } else if (user.type === "supervisor") {
          dateCounts[dateKey].supervisor += 1;
        }
      }
    });

    return Object.entries(dateCounts).map(([date, counts]) => ({
      x: new Date(date),
      manager: counts.manager,
      supervisor: counts.supervisor
    }));
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this user?")) return;
  
    try {
      await deleteDoc(doc(db, "Users", id));
      
      // Update local state after successful deletion
      setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
  
      // Refresh the chart data after user deletion
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete the user. Please try again.");
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
  
    const updatedUser = {
      email: formData.get("email"),
      first_name: formData.get("first_name"),
      last_name: formData.get("last_name"),
      type: formData.get("type")
    };
  
    try {
      if (editingUser) {
        // Update existing user in Firestore
        const userRef = doc(db, "Users", editingUser.id);
        await updateDoc(userRef, updatedUser);
  
        // Update state with new user info
        setUsers(prevUsers =>
          prevUsers.map(user =>
            user.id === editingUser.id ? { ...user, ...updatedUser } : user
          )
        );
      } else {
        // Create a new user in Firestore
        const docRef = await addDoc(collection(db, "Users"), {
          ...updatedUser,
          last_login: null,  // Ensure new users have null login until first login
          login_count: 0, // Start with zero logins
          created_at: new Date() // Optional: Track user creation time
        });
  
        // Update state with new user
        setUsers(prevUsers => [...prevUsers, { id: docRef.id, ...updatedUser, last_login: null, login_count: 0 }]);
      }
  
      // Close modal and refresh data
      setEditingUser(null);
      setIsModalOpen(false);
      fetchUsers(); // Refresh UI
  
    } catch (error) {
      console.error("Error updating Firestore:", error);
      alert("Failed to update user information. Please try again.");
    }
  };
  
  

  const fetchUsers = async () => {
    try {
      const userCollection = collection(db, "Users");
      const userSnapshot = await getDocs(userCollection);
  
      const userList = userSnapshot.docs.map((doc) => {
        const userData = doc.data();
        return {
          id: doc.id,
          ...userData,
          last_login: userData.last_login ? new Date(userData.last_login.seconds * 1000) : null,
          login_count: userData.login_count || 0
        };
      });
  
      setUsers(userList);
  
      // Count managers and supervisors
      const managerTotal = userList.filter(user => user.type === "manager").length;
      const supervisorTotal = userList.filter(user => user.type === "supervisor").length;
      setManagerCount(managerTotal);
      setSupervisorCount(supervisorTotal);
  
      // Group users by creation date and role
      const groupedData = groupByDateAndType(userList);
  
      setManagerData(groupedData.map(data => ({ x: data.x, y: data.manager })));
      setSupervisorData(groupedData.map(data => ({ x: data.x, y: data.supervisor })));
  
      // Process login times for high-volume use tracking
      const loginTimes = userList
        .filter(user => user.last_login)
        .map(user => new Date(user.last_login));
  
      setLoginActivityData(processLoginTimes(loginTimes));
  
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    console.log("📥 users (to be passed as userList):", users);
    console.log("📥 divisionCounts (to be passed as divisionCountsFromUsers):", divisionCounts);
  }, [users, divisionCounts]);
  

  useEffect(() => {
    const fetchDivisionCounts = async () => {
      try {
        const today = new Date();
        const weekId = `week-${getWeekNumber(today)}`;
        const weeklyRef = doc(db, "weekly_reports", weekId);
        const weeklySnapshot = await getDoc(weeklyRef);
    
        if (weeklySnapshot.exists()) {
          const weeklyData = weeklySnapshot.data();
          console.log("✅ User Management - Weekly Report Data:", weeklyData.divisionCounts);
          setDivisionCounts(weeklyData.divisionCounts || {});
        } else {
          console.log("❌ No weekly report found for this week.");
          setDivisionCounts({});
        }
      } catch (error) {
        console.error("Error fetching division counts from weekly report:", error);
      }
    };
    
  
    fetchDivisionCounts();
  }, []);


  const processLoginTimes = (loginTimes) => {
    const hourlyCounts = Array(24).fill(0);
    loginTimes.forEach(time => {
      const hour = time.getHours();
      hourlyCounts[hour] += 1;
    });

    return hourlyCounts.map((count, hour) => ({ x: `${hour}:00`, y: count }));
  };

  function getWeekNumber(date) {
    const firstJan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil((((date - firstJan) / 86400000) + firstJan.getDay() + 1) / 7);
  }
  


  return (
    <div className="user-management">
  <h2>User Management</h2>

  <div className="user-stats">
    <p><strong>Managers:</strong> {managerCount}</p>
    <p><strong>Supervisors:</strong> {supervisorCount}</p>
  </div>

  <button className="add-user-btn" onClick={() => { setEditingUser(null); setIsModalOpen(true); }}>
    Add User
  </button>
  <button
  className="add-user-btn"
  onClick={() =>
    navigate("/weekly-report", {
      state: {
        userList: users,
        divisionCountsFromUsers: divisionCounts
      }
    })
  }
>
  User Analytics
</button>




      <div className="report-section">
  <h3>Service Updates by Division</h3>
  <table className="mini-table">
    <thead>
      <tr>
        <th>Division</th>
        <th>Updates</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(divisionCounts).length > 0 ? (
        Object.entries(divisionCounts).map(([division, count]) => (
          <tr key={division}>
            <td>{division}</td>
            <td>{count}</td>
          </tr>
        ))
      ) : (
        <tr><td colSpan="2">No service updates recorded per division.</td></tr>
      )}
    </tbody>
  </table>
</div>


  

  <table className="user-table">
    <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Last Login</th>
        <th>Login Count</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {users.map(user => (
        <tr key={user.id}>
          <td>{user.first_name}</td>
          <td>{user.last_name}</td>
          <td>{user.email}</td>
          <td>{user.type}</td>
          <td>{user.last_login ? user.last_login.toLocaleString() : "N/A"}</td>
          <td>{user.login_count || 0}</td>
          <td>
            <button className="edit-btn" onClick={() => { setEditingUser(user); setIsModalOpen(true); }}>
              Edit
            </button>
            <button className="delete-btn" onClick={() => handleDelete(user.id)}>
              Delete
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={() => setIsModalOpen(false)}>✖</button>
            <form onSubmit={handleSave} className="modal-form">
              <input name="first_name" placeholder="First Name" defaultValue={editingUser?.first_name || ""} required />
              <input name="last_name" placeholder="Last Name" defaultValue={editingUser?.last_name || ""} required />
              <input name="email" type="email" placeholder="Email" defaultValue={editingUser?.email || ""} required />
              <input name="type" placeholder="Role" defaultValue={editingUser?.type || ""} required />
              <button type="submit">Save</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;
