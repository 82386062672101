import React, { useState } from "react";
import styled from "styled-components";
import { FaPaperPlane, FaTimes, FaComments } from "react-icons/fa";

// 🔹 Styled Components with Header Colors
const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 320px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ChatHeader = styled.div`
  background: #296b1a;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
`;

const ChatMessages = styled.div`
  max-height: 250px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Message = styled.div`
  background: ${({ sender }) => (sender === "user" ? "#296b1a" : "#f1f1f1")};
  color: ${({ sender }) => (sender === "user" ? "white" : "black")};
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 75%;
  align-self: ${({ sender }) => (sender === "user" ? "flex-end" : "flex-start")};
`;

const ChatInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #ddd;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background: #f9f9f9;
  outline: none;
`;

const SendButton = styled.button`
  background: #296b1a;
  color: white;
  border: none;
  padding: 8px 12px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &:hover {
    background: #1f5315;
  }
`;

const ChatButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #296b1a;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #1f5315;
  }
`;

const ChatWidget = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const sendMessage = async () => {
    if (!input.trim()) return;

    const newMessages = [...messages, { text: input, sender: "user" }];
    setMessages(newMessages);
    setInput("");

    try {
      const response = await fetch(`/api/openai`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          messages: [
            { role: "system", content: "You are a landscaping expert AI assistant. Provide detailed, professional guidance on topics such as plant care, irrigation, hardscaping, lawn maintenance, and outdoor design. Offer practical advice tailored to landscaping technicians." },
            ...newMessages.map((m) => ({
              role: m.sender === "user" ? "user" : "assistant",
              content: m.text,
            })),
          ],
        }),
      });

      const data = await response.json();

      if (!data || !data.choices || data.choices.length === 0) {
        console.error("Error fetching OpenAI response:", data);
        setMessages([
          ...newMessages,
          { text: "Sorry, something went wrong. Please try again.", sender: "assistant" },
        ]);
        return;
      }

      setMessages([...newMessages, { text: data.choices[0].message.content, sender: "assistant" }]);
    } catch (error) {
      console.error("Error fetching OpenAI response:", error);
      setMessages([
        ...newMessages,
        { text: "Error connecting to AI. Please check your internet and try again.", sender: "assistant" },
      ]);
    }
  };

  return (
    <>
      {!isOpen ? (
        <ChatButton onClick={() => setIsOpen(true)} aria-label="Open Chat">
          <FaComments />
        </ChatButton>
      ) : (
        <ChatContainer>
          <ChatHeader>
            AI Chat
            <CloseButton onClick={() => setIsOpen(false)}>
              <FaTimes />
            </CloseButton>
          </ChatHeader>
          <ChatMessages>
            {messages.map((msg, index) => (
              <Message key={index} sender={msg.sender}>
                {msg.text}
              </Message>
            ))}
          </ChatMessages>
          <ChatInputContainer>
            <ChatInput
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type a message..."
              onKeyPress={(e) => e.key === "Enter" && sendMessage()}
            />
            <SendButton onClick={sendMessage} aria-label="Send Message">
              <FaPaperPlane />
            </SendButton>
          </ChatInputContainer>
        </ChatContainer>
      )}
    </>
  );
};

export default ChatWidget;
