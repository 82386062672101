import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';
import { getAuth } from 'firebase/auth';

const ReportContainer = styled.div`
  padding: 1rem;
  border: 1px solid #ccc;
  margin: 1rem 0;
  border-radius: 8px;
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
`;

const SavedReports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
      try {
          const savedData = localStorage.getItem('savedJobForm');

          if (savedData) {
              const parsedData = JSON.parse(savedData);
              console.log("Retrieved from localStorage:", parsedData); // Debugging
              setReports([parsedData]); // Wrap in an array
          } else {
              console.log("No saved reports found in localStorage.");
          }
      } catch (error) {
          console.error("Error retrieving saved reports:", error);
      } finally {
          setLoading(false);
      }
  }, []);

  const handleEditReport = (report) => {
      navigate('/job-form', { state: { initialData: report } }); // Navigate to JobForm with saved data
  };

  if (loading) return <p>Loading saved reports...</p>;
  if (reports.length === 0) return <p>No saved reports found.</p>;

  return (
      <div>
          <h2>Saved Reports</h2>
          {reports.map((report, index) => (
              <div key={index} style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "10px" }}>
                  <p><strong>Supervisor:</strong> {report.supervisorName || "N/A"}</p>
                  <p><strong>Property:</strong> {report.property || "N/A"}</p>
                  <p><strong>Date:</strong> {report.date || "N/A"}</p>
                  <p><strong>Services:</strong> {report.serviceTypes?.join(", ") || "N/A"}</p>
                  <p><strong>Notes:</strong> {report.notes || "N/A"}</p>
                  <button onClick={() => handleEditReport(report)}>Resume Editing</button>
              </div>
          ))}
      </div>
  );
};

export default SavedReports;
