import React, { useState } from "react";
import { Button, TextField, Card, CardContent, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";

const productionRates = {
  "Bed Prep": 75, // SF/MH
  "Demo - Beds": 50, // SQ FT/MH
  "Demo - Sod": 100, // SQ FT/MH
  "Mulch - Bulk": 1 // CY/MH
};

const EstimatorBidTool = () => {
  const [client, setClient] = useState("");
  const [email, setEmail] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [serviceType, setServiceType] = useState("Bed Prep");
  const [quantity, setQuantity] = useState(0);
  const [laborCostPerHour, setLaborCostPerHour] = useState(50);
  const [materialCost, setMaterialCost] = useState(0);
  const [overhead, setOverhead] = useState(10);
  const [profitMargin, setProfitMargin] = useState(20);

  const calculateTotal = () => {
    const productionRate = productionRates[serviceType] || 1;
    const laborHours = quantity / productionRate;
    const laborCost = laborHours * laborCostPerHour;
    const overheadCost = (laborCost + materialCost) * (overhead / 100);
    const profit = (laborCost + materialCost + overheadCost) * (profitMargin / 100);
    return laborCost + materialCost + overheadCost + profit;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Estimate Invoice", 20, 20);
    doc.text(`Client: ${client}`, 20, 30);
    doc.text(`Job Description: ${jobDescription}`, 20, 40);
    doc.text(`Service Type: ${serviceType}`, 20, 50);
    doc.text(`Quantity: ${quantity}`, 20, 60);
    doc.text(`Labor Cost per Hour: $${laborCostPerHour}`, 20, 70);
    doc.text(`Material Cost: $${materialCost}`, 20, 80);
    doc.text(`Overhead: ${overhead}%`, 20, 90);
    doc.text(`Profit Margin: ${profitMargin}%`, 20, 100);
    doc.text(`Total Estimated Cost: $${calculateTotal().toFixed(2)}`, 20, 110);
    doc.save("Estimate_Invoice.pdf");
  };

  return (
    <Card sx={{ maxWidth: 500, mx: "auto", p: 3, boxShadow: 3 }}>
      <CardContent>
        <h2 style={{ textAlign: "center", marginBottom: "16px" }}>Estimator Bid Tool</h2>
        <TextField fullWidth label="Client Name" value={client} onChange={(e) => setClient(e.target.value)} margin="normal" />
        <TextField fullWidth label="Client Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
        <TextField fullWidth label="Job Description" value={jobDescription} onChange={(e) => setJobDescription(e.target.value)} margin="normal" />
        <FormControl fullWidth margin="normal">
          <InputLabel>Service Type</InputLabel>
          <Select value={serviceType} onChange={(e) => setServiceType(e.target.value)}>
            {Object.keys(productionRates).map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField fullWidth type="number" label="Quantity" value={quantity} onChange={(e) => setQuantity(parseFloat(e.target.value) || 0)} margin="normal" />
        <TextField fullWidth type="number" label="Material Cost" value={materialCost} onChange={(e) => setMaterialCost(parseFloat(e.target.value) || 0)} margin="normal" />
        <TextField fullWidth type="number" label="Overhead %" value={overhead} onChange={(e) => setOverhead(parseFloat(e.target.value) || 10)} margin="normal" />
        <TextField fullWidth type="number" label="Profit Margin %" value={profitMargin} onChange={(e) => setProfitMargin(parseFloat(e.target.value) || 20)} margin="normal" />
        <p style={{ marginTop: "16px", fontWeight: "bold", textAlign: "center" }}>Total Estimate: ${calculateTotal().toFixed(2)}</p>
        <Button variant="contained" color="primary" fullWidth onClick={generatePDF} sx={{ mt: 2 }}>Generate Invoice PDF</Button>
      </CardContent>
    </Card>
  );
};

export default EstimatorBidTool;
  