import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../Firebase Functions/firebase";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { ChemicalApplicationPDF } from "../PDF Functions/ChemAppPDF";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;



const Select = styled.select`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
`;

const Checkbox = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
`;

const PDFContainer = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 80%;
  background: white;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  overflow: hidden;
`;

const EditChemicalForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const initialData = location.state?.form || {};

  const [formData, setFormData] = useState(initialData);
  const [showPDF, setShowPDF] = useState(false);
  const [newRecord, setNewRecord] = useState({
      applicationDate: '',
      applicator: '',
      ownerOperatorNumber: '',
      applicatorId: '',
      project: '',
      address: '',
      city: '',
      state: '',
      temperature: '',
      wind: '',
      areaTreated: '',
      areaUnit: '',
      usedRestrictedPesticide: '',
      chemicals: [
        { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
        { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
        { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
        { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      ],
      checkboxes: {
        turfFertilizer: false,
        turfPostEmerge: false,
        turfPreEmerge: false,
        turfInsect: false,
        antControl: false,
        bedFertilizer: false,
        bedPostEmerge: false,
        bedPreEmerge: false,
        tsInsect: false,
        ccPostEmerge: false,
        tsDisease: false,
        tsFertilizer: false,
        palmFertilizer: false,
        palmFungicide: false,
      },
      notes: '',
    });

    const chemicals = {
        Antitranspirants: ['Trans Film - label', 'Trans Film - SDS'],
        Fungicides: [
          'Dithane 75 DF RAINSHIELD - label',
          'Dithane 75 DF RAINSHIELD - SDS',
          'Eagle 20 EW (myclobutanil)- label',
          'Eagle 20 EW (myclobutanil) - SDS',
          'Headway (axoxystrobin) -label',
          'Heritage (azoxystrobin) -label',
          'Lesco Mancozeb 4F (mancozeb) - label',
          'Mefenoxam 2 AQ (mefenoxam) label',
          'Mefenoxam 2 AQ (mefenoxam) - label',
          'Myclobutanil 20 EW - label',
          'Myclobutanil 20 EW - SDS',
          'Pedigree SC (flutolanil) -label',
          'Pedigree SC (flutolanil) - SDS',
          'Propiconazole 14.3 (propiconazole) - label',
          'Propiconazole 14.3 (propiconazole) - SDS',
          'Strobe 2 L (axoystrobin) - label',
          'Subdue Maxx (mefenoxam) -label',
          'Transom 4.5 F (thiophanate-methyl) - label',
          'Transom 4.5 F (thiophanate-methyl) -SDS',
          'T-Storm F (thiophanate-methyl) - label',
          'T-Store F (thiophanate-methyl) -SDS',
          'Turfcide 10 G (PCNB) -SDS',
          'Turfcide 10 G (PCNB) -label',
          'Xzemplar (fluxapyroxad) - label',
          'Xzemplar (fluxapyroxad) - SDS',
        ],
        GrowthRegulators: [
          'Pac-Low (paclobutrazole) - label',
          'Tide Paclo 2 SC (paclobutrazole) - label',
          'Pac-Low (paclobutrazole) -label',
          'Pac-Low (pacloburtrazole) - label',
          'Tide Paclo 2 SC (paclobutrazole) - label (a)',
          'Tide Paclo 2 SC (paclobutrazole) -SDS',
          'Tide Paclo 2 SC (paclobutrazole) - label',
          'Trim Tect (paclobutrazole) - label',
          'Trim Tect (paclobutrazole) - SDS',
        ],
        Herbicides: [
          'Avenue South (4-Way) - label',
          'Barricade 4FL (prodiamine) - label',
          'Blindside (sulfentrazone) - label',
          'Celcius WG (indosulfuron) - label (a)',
          'Certainty (sulfosulfuron) - label',
          'Drive XLR8 (auinclorac) - label',
          'Fusilade II T&O (fluazifop) - label',
          'Lesco Three-Way (3 Way) - label',
          'MSM Turf (metsulfuron) - label',
          'Speed Zone Southern (4-Way) - label',
        ],
        Insecticides: [
          'Acelepryn (chlorantraniliprole) - label',
          'Acephate 90 Prills (acephate) - label',
          'Addvion Fire Ant Bait (indoxibarb) - label',
          'Arena 50 WDG(clothianidin) - label',
          'Cross Check Plus (bifenthrin) - label',
        ],
        Surfactants: ['Lesco Methylated Seed Oil - label', 'Lesco Methylated Seed Oil - SDS'],
      };

  // Fetch latest data from Firestore
  useEffect(() => {
    const fetchFormData = async () => {
      if (!id || Object.keys(initialData).length) return; // Skip if data is already passed

      try {
        const docRef = doc(db, "ChemAppForms", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setFormData(docSnap.data());
        } else {
          console.warn("⚠️ Form document not found.");
        }
      } catch (error) {
        console.error("❌ Error fetching form:", error);
      }
    };

    fetchFormData();
  }, [id, initialData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle checkbox changes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      checkboxes: { ...prev.checkboxes, [name]: checked },
    }));
  };

  // Handle chemical field changes
  const handleChemicalChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedChemicals = [...prev.chemicals];
      updatedChemicals[index][name] = value;

      // Reset product if category changes
      if (name === "category") {
        updatedChemicals[index].product = "";
      }

      return { ...prev, chemicals: updatedChemicals };
    });
  };

  // Handle PDF Preview
  const handlePDFPreview = async () => {
    setShowPDF(true);
  };

  // Handle form submission to update Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "ChemAppForms", id);
      await updateDoc(docRef, formData);
      console.log("✅ Form successfully updated!");

      navigate("/manager-spray-list"); // Redirect back
    } catch (error) {
      console.error("❌ Error updating form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const operators = {
    Alabama: [
      { id: "2002514", name: "Jeremy Carter" },
      { id: "2004088", name: "Charles Hollingsworth" },
      { id: "2004223", name: "Jessica Gros" },
      { id: "2000867", name: "Dennis Pritchett Jr" },
    ],
      Florida: [
        // SRO Operators
        { id: "JE313023", name: "Brian Todd Waddle", location: "SRO" },
        { id: "JE221227", name: "Elijamir Dasilva", location: "SRO" },
        { id: "JE307918", name: "Encarnacion Ramon-Son", location: "SRO" },
        { id: "JE192032", name: "Tammy Williford", location: "SRO" },
        { id: "JE282437", name: "Tracy L. Peele", location: "SRO" },
        { id: "JE300552", name: "Raymundo Barbosa Barajas", location: "SRO" },
        { id: "JE300565", name: "Oscar Padilla Saldana", location: "SRO" },
        { id: "JE314570", name: "Luis Igancio Plascencia Morales", location: "SRO" },
        { id: "JE319723", name: "Cesar Hugo Padilla-Saldana", location: "SRO" },
        { id: "JE319713", name: "Alberto Aldama-Guerra", location: "SRO" },
        { id: "JE319136", name: "Christian Jackson", location: "SRO" },
        { id: "JE319137", name: "Ashton Martin", location: "SRO" },
        { id: "JE319133", name: "John Borja", location: "SRO" },
        { id: "JE327071", name: "Angel Merino Sanchez", location: "SRO" },
        { id: "JE337783", name: "Nathan Miles Taylor", location: "SRO" },
        { id: "JE337784", name: "Jose Banda Vega", location: "SRO" },
        { id: "JE337780", name: "Raul Rios Reyes", location: "SRO" },
        { id: "JE347835", name: "Mihael Lewis", location: "SRO" },
        { id: "JE347832", name: "Erik Frederick", location: "SRO" },
        { id: "JE330239", name: "Danielle Kathleen Burch", location: "SRO" },
        { id: "JE347834", name: "Kolby Gordon", location: "SRO" },
        { id: "JE245474", name: "Christopher Lee Peppers", location: "SRO" },
        { id: "JE358828", name: "Jacob Alexander Edelman", location: "SRO" },
        { id: "JB279027", name: "RCI SRO Office", location: "SRO" }, // New Entry
    
        // Apopka Operators
        { id: "JE360496", name: "Danilo Castillo Rodriguez", location: "APOPKA" },
        { id: "JE360497", name: "Risarui Akberti Bojorque-Soto", location: "APOPKA" },
        { id: "JE360498", name: "Rodolfo Jimenez", location: "APOPKA" },
        { id: "JE360499", name: "Nelson Dort", location: "APOPKA" },
        { id: "JE360500", name: "Victor Manuel Lopez-Mora", location: "APOPKA" },
        { id: "JE360501", name: "Wikenson Ciceron", location: "APOPKA" },
        { id: "JE360502", name: "Mario Eduardo Rubio Valdez", location: "APOPKA" },
        { id: "JE360503", name: "Yeandry Batista Ramirez", location: "APOPKA" },
        { id: "JE360504", name: "Anthony D. Scott", location: "APOPKA" },
        { id: "JE360505", name: "Alvin Coleman", location: "APOPKA" },
        { id: "JE360506", name: "Cesar Guerra Penate", location: "APOPKA" },
        { id: "JE360507", name: "Joel Gonzales Socarrde", location: "APOPKA" },
        { id: "JE315698", name: "Jose Coca Sanchez", location: "APOPKA" },
        { id: "JE360495", name: "Eiser Ramirez Guerra", location: "APOPKA" },
        { id: "JE83762", name: "William G Sandritter", location: "APOPKA" },
        { id: "JE360509", name: "Roberto Delgado Garcia", location: "APOPKA" },
        { id: "JE147264", name: "Lorenzo Wright Jr", location: "APOPKA" },
        { id: "JE360497", name: "Rosario Alberto Bojorquez-Soto", location: "APOPKA" },
        { id: "JE295247", name: "John Neuber", location: "APOPKA" },
        { id: "JE361267", name: "Giovanni Keron Forrest", location: "APOPKA" },
        { id: "JE361268", name: "Gabriel Munoz", location: "APOPKA" },
        { id: "JE361269", name: "Omar Eastellanos", location: "APOPKA" },
        { id: "JE361270", name: "Ezekiel Titus Davis", location: "APOPKA" },
        { id: "JE361271", name: "Carol Orlando Zackery", location: "APOPKA" },
        { id: "JE314244", name: "Jonathan Alvelo", location: "APOPKA" },
        { id: "JB359443", name: "RCI Apopka Office", location: "APOPKA" }, // New Entry
    
        // PCB Operators
        { id: "JE329657", name: "Antonio Cervantes Galvan", location: "PCB" },
        { id: "JE329653", name: "Evon Baker", location: "PCB" },
        { id: "JE329654", name: "Francisco Lozano Carreon", location: "PCB" },
        { id: "JE329655", name: "Irving Valle Castro", location: "PCB" },
        { id: "JE331315", name: "Jose Daniel-Perez Garcia", location: "PCB" },
        { id: "JE331317", name: "Jesus Alexis- Alvarez Berrellez", location: "PCB" },
        { id: "JE331318", name: "Joel Lugo Villanueva", location: "PCB" },
        { id: "JE331319", name: "Ismael Osorio Perez", location: "PCB" },
        { id: "JE331324", name: "Cruz Prudencio Marco Antonio", location: "PCB" },
        { id: "JE331327", name: "Luis Gerardo Serrano Andrade", location: "PCB" },
        { id: "JE331328", name: "Eduardo Ibarra Hernandez", location: "PCB" },
        { id: "JE331330", name: "Jose Felix Barajas Florido", location: "PCB" },
        { id: "JE331331", name: "Vicente Barajas Florido", location: "PCB" },
        { id: "JE331332", name: "Jose Cruz Pardo", location: "PCB" },
        { id: "JB326363", name: "RCI PCB Office", location: "PCB" }, // New Entry
    
        // Agricultural Dealer License (No Specific Location)
        { id: "AD1763", name: "Agricultural Dealer License" },
        { id: "JB326363", name: "RCI PCB Office" },
        { id: "JB359443", name: "RCI SRO Office" },
        { id: "JB359443", name: "RCI SRO Office" },
         // New Entry
      ],
  
    
    Georgia: [{ id: "4347", name: "Brandon Stegall" }],

    Louisiana: [
      { id: "002D7N", name: "Charles Hollingsworth" },
      { id: "003JZX", name: "Jessica Gros" },
      { id: "003K10", name: "Bryan Martin" },
      { id: "003K01", name: "John Borja" },
      { id: "0045A5", name: "Ashton Martin" },
      { id: "003JZG", name: "Megan Olden" },
      { id: "003M5H", name: "Bill Macmurdo" },
      { id: "003K1A", name: "Russell S Harris III" },
      { id: "0040AG", name: "Russell B Watson" },
    ],

    Mississippi: [
      { id: "CA17420", name: "Ashton Martin" },
      { id: "CA17419", name: "Charles Hollingsworth" },
      { id: "CA17407", name: "John Andrew Borja" },
    ],
    Texas: [
      { id: "861041", name: "James Gravett" },
      { id: "922081", name: "Zackery Kidd" },
      { id: "806734", name: "Charles Hollingsworth" },
      { id: "876063", name: "Jessica Gros" },
    ],
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h2>Edit Chemical Application</h2>

      {/* Location Section */}
      <h3>Location</h3>
      <Input name="address" value={formData.address || ""} onChange={handleInputChange} />
      <Input name="city" value={formData.city || ""} onChange={handleInputChange} />
      <Input name="state" value={formData.state || ""} onChange={handleInputChange} />

      {/* Application Details */}
      <h3>Chemical Application Details</h3>
      <Input name="applicator" placeholder="Applicator ID" value={formData.applicator || ""} onChange={handleInputChange} />
      <Input name="applicatorId" value={formData.applicatorId || ""} onChange={handleInputChange} />
      <Input
  list="operatorList"
  name="ownerOperatorNumber"
  placeholder="Select or enter Operator ID"
  value={formData.ownerOperatorNumber || ""}
  onChange={handleInputChange}
  required
/>
<datalist id="operatorList">
  {Object.entries(operators).map(([state, ops]) => (
    <React.Fragment key={state}>
      {ops.map((op) => (
        <option key={op.id} value={op.id}>
          {op.id} - {op.name} {op.location ? `(${op.location})` : ""}
        </option>
      ))}
    </React.Fragment>
  ))}
</datalist>

      <Input name="project" placeholder="Project" value={formData.project || ""} onChange={handleInputChange} />
      <Input name="applicationDate" placeholder="mm/dd/yyy" type="date" value={formData.applicationDate || ""} onChange={handleInputChange} />
      <Input name="temperature" placeholder="Temperature(F)" value={formData.temperature || ""} onChange={handleInputChange} />
      <Input name="wind" placeholder="Wind (mph)" value={formData.wind || ""} onChange={handleInputChange} />
      <label style={{ fontWeight: "bold" }}>
  <span style={{ color: "red" }}>*</span> Used restricted general use pesticide?
</label>

<CheckboxContainer>
  <Checkbox>
    <input
      type="radio"
      name="usedRestrictedPesticide"
      value="Yes"
      checked={formData.usedRestrictedPesticide === "Yes"}
      onChange={handleInputChange}
      required
    />
    Yes
  </Checkbox>
  <Checkbox>
    <input
      type="radio"
      name="usedRestrictedPesticide"
      value="No"
      checked={formData.usedRestrictedPesticide === "No"}
      onChange={handleInputChange}
      required
    />
    No
  </Checkbox>
</CheckboxContainer>


      {/* Checkboxes */}
      <h3>Application Methods</h3>
      <CheckboxContainer>
        {Object.keys(formData.checkboxes || {}).map((key) => (
          <label key={key}>
            <input type="checkbox" name={key} checked={formData.checkboxes[key]} onChange={handleCheckboxChange} />
            {key.replace(/([A-Z])/g, " $1").trim()}
          </label>
        ))}
      </CheckboxContainer>

      {/* Chemical Applications */}
      {formData.chemicals?.map((chemical, index) => (
        <div key={index}>
          <h3>Chemical {index + 1}</h3>
          <Select name="category" value={chemical.category} onChange={(e) => handleChemicalChange(index, e)}>
            <option value="">Select Category</option>
            {Object.keys(chemicals).map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </Select>
          <Select name="product" value={chemical.product} onChange={(e) => handleChemicalChange(index, e)} disabled={!chemical.category}>
            <option value="">Select Product</option>
            {chemical.category &&
              chemicals[chemical.category]?.map((product) => (
                <option key={product} value={product}>
                  {product}
                </option>
              ))}
          </Select>
          <Grid>
              <Input
                name="epaNumber"
                placeholder="EPA Number"
                value={chemical.epaNumber}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="lotNumber"
                placeholder="Lot Number"
                value={chemical.lotNumber}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="rate"
                placeholder="Rate"
                value={chemical.rate}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="per"
                placeholder="Per"
                value={chemical.per}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="totalApplied"
                placeholder="Total Applied"
                value={chemical.totalApplied}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="totalConc"
                placeholder="Total Concentration"
                value={chemical.totalConc}
                onChange={(e) => handleChemicalChange(index, e)}
              />
            </Grid>
          
        </div>
        
      ))}
      

      

      {/* Notes */}
      <Input name="notes" value={formData.notes || ""} onChange={handleInputChange} />

      {/* Buttons */}
      <Button type="button" onClick={handlePDFPreview}>
        Preview PDF
      </Button>
      <Button type="submit">Save Changes</Button>

      {/* PDF Viewer */}
      {showPDF && (
        <PDFContainer>
          <PDFViewer width="100%" height="100%">
            <ChemicalApplicationPDF recordData={formData} />
          </PDFViewer>
          <Button onClick={() => setShowPDF(false)}>Close</Button>
        </PDFContainer>
      )}
    </Form>
  );
};

export default EditChemicalForm;
