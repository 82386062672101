import styled from 'styled-components';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/AuthProvider'; // Use AuthProvider
import { FaThermometerHalf, FaWind, FaQuestionCircle, FaComments } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FaBell } from 'react-icons/fa';
import { db } from '../Firebase Functions/firebase';
import { collection, getDocs, doc, updateDoc, query, where, deleteDoc } from "firebase/firestore";
import ChatWidget from '../ChatWidget/ChatWidget';


// Styled Components
const HeaderContainer = styled.header`
  background-color: #296b1a;
  color: white;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;

  @media (max-width: 768px) {
    padding: 0.5rem;
    flex-direction: column;
    min-height: auto;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const FormControl = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #f9f9f9;

  &:focus {
    border-color: #007820;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 120, 32, 0.3);
  }

  & + & {
    margin-top: 1rem;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: #333;
  background-color: #f9f9f9;
  height: 2.8rem; /* Matches the height of the input fields */

  &:focus {
    border-color: #007820;
    outline: none;
    box-shadow: 0 0 4px rgba(0, 120, 32, 0.3);
  }

  resize: none; /* Prevent resizing */
`;

const ModalLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
`;

const InstructionText = styled.p`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #4a5568;
  font-weight: 500;
  line-height: 1.5;
`;

const ModalButtons = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

const PrimaryButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #007820;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 120, 32, 0.2);
  margin-left: 1rem;

  &:hover:not(:disabled) {
    background-color: #005c18;
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 120, 32, 0.3);
  }

  &:disabled {
    background-color: #88b794;
    cursor: not-allowed;
    box-shadow: none;
  }
`;

const SecondaryButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #d1d5db;
  color: #333;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #b8bbc2;
  }
`;

const LogoTitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 40px;

  @media (max-width: 768px) {
    height: 30px;
    margin-bottom: 0.5rem;
  }
`;

const HeaderTitle = styled.h1`
  margin: 0 0 0 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  color: #f1f1f1;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 3px;
    background-color: #e74c3c;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.5rem;
  }
`;

const HeaderButton = styled.button`
  background-color: #ffffff;
  color: #296b1a;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: #e8f5e9;
  }
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffffff;
  }

  svg {
    color: #ffffff;
    width: 24px;
    height: 24px;
    transition: color 0.3s ease;
  }

  &:hover svg {
    color: #296b1a;
  }
`;

const WeatherWidgetContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
`;

const WeatherData = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  color: white;

  svg {
    color: white;
    font-size: 1.2em;
  }
`;

// Weather Widget Component
const WeatherWidget = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeather = async (latitude, longitude) => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_API_KEY}&q=${latitude},${longitude}`
        );
        const { current } = response.data;
        setWeather({
          temperature: current.temp_f,
          windSpeed: current.wind_mph,
          windDirection: current.wind_dir,
        });
      } catch (err) {
        setError('Unable to fetch weather data');
      } finally {
        setLoading(false);
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchWeather(latitude, longitude);
        },
        () => {
          setError('Location access is needed');
          setLoading(false);
        }
      );
    } else {
      setError('Geolocation not supported');
      setLoading(false);
    }
  }, []);

  if (loading) return <WeatherData>Loading...</WeatherData>;
  if (error) return <WeatherData>Error fetching weather</WeatherData>;
  if (!weather) return null;

  return (
    <WeatherWidgetContainer>
      <WeatherData aria-label={`Temperature: ${weather.temperature}°F`}>
        <FaThermometerHalf />
        {Math.round(weather.temperature)}°F
      </WeatherData>
      <WeatherData aria-label={`Wind: ${weather.windSpeed} mph ${weather.windDirection}`}>
        <FaWind />
        {weather.windSpeed} mph {weather.windDirection}
      </WeatherData>
    </WeatherWidgetContainer>
  );
};

const TRANSLATIONS_EN = {
  email: {
    requestAssistance: "Request Assistance",
    instructions: 'Please type your questions in the "Message" section below. Our team will respond to you promptly.',
    subject: "Subject",
    subjectPlaceholder: "Enter subject",
    message: "Message",
    messagePlaceholder: "Enter your message",
    close: "Close",
    send: "Send Email",
    sentSuccessfully: "Email sent successfully!",
    failedToSend: "Failed to send email",
    errorSending: "Error sending email"
  }
};

// EmailModal Component
const EmailModal = ({ onClose, user }) => {
  const { t } = useTranslation();
  
  const [emailData, setEmailData] = useState({
    to: 'eporter@rotoloconsultants.com',
    subject: 'ServiceNow! Help Ticket',
    text: '',
  });

  // Ensure the user data updates correctly when the component renders
  useEffect(() => {
    if (user) {
      setEmailData((prev) => ({
        ...prev,
        text: '',
      }));
    }
  }, [user]); // Re-run when `user` changes

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!emailData.to || !emailData.text.trim()) {
      alert("Recipient and message are required.");
      return;
    }
  
    const senderEmail = user?.email || "Unknown";
  
    const response = await fetch('/api/send-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailData: {
          to: emailData.to,
          subject: emailData.subject,
          text: `Sender Email: ${senderEmail}\n\n${emailData.text}`,
          html: `<p><strong>Sender Email:</strong> ${senderEmail}</p><p>${emailData.text}</p>`,
        },
      }),
    });
  
    if (response.ok) {
      alert("Email sent successfully!");
      onClose();
    } else {
      const error = await response.json();
      alert(`Failed to send email: ${error.error}`);
    }
  };
  

  return (
    <ModalOverlay>
      <ModalContent>
        <h2>{t('email.requestAssistance', TRANSLATIONS_EN.email.requestAssistance)}</h2>
        <InstructionText>
          {t('email.instructions', TRANSLATIONS_EN.email.instructions)}
        </InstructionText>
        
        {/* 🔒 Read-only sender information */}
        <div style={{
             marginBottom: '1rem', 
             padding: '1rem', 
             background: '#f1f1f1', 
             borderRadius: '8px', 
             color: '#333' // Ensure text is dark
          }}>
  <p><strong>Sender Email:</strong> {user?.email || "Unknown"}</p>
</div>


        <form onSubmit={handleSubmit}>
          <div>
            <ModalLabel>{t('email.subject', TRANSLATIONS_EN.email.subject)}:</ModalLabel>
            <FormControl
              type="text"
              name="subject"
              value={emailData.subject}
              readOnly
              style={{ backgroundColor: '#e9ecef' }} 
            />
          </div>
          <div>
            <ModalLabel>{t('email.message', TRANSLATIONS_EN.email.message)}:</ModalLabel>
            <Textarea
              name="text"
              value={emailData.text}
              onChange={handleChange}
              placeholder={t('email.messagePlaceholder', TRANSLATIONS_EN.email.messagePlaceholder)}
            />
          </div>
          <ModalButtons>
            <SecondaryButton type="button" onClick={onClose}>
              {t('email.close', TRANSLATIONS_EN.email.close)}
            </SecondaryButton>
            <PrimaryButton type="submit">
              {t('email.send', TRANSLATIONS_EN.email.send)}
            </PrimaryButton>
          </ModalButtons>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};



// Header Component
const Header = () => {
  const { t, i18n } = useTranslation();
  const { currentUser, handleLogout } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Auth Context Current User:", currentUser);
    // Fetch notifications for the current user
    const fetchNotifications = async () => {
      if (!currentUser) return;
      const notificationsCollection = collection(db, 'Notifications');
      const notificationsQuery = query(
        notificationsCollection,
        where('to', '==', currentUser.email || 'Unassigned')
      );
      const snapshot = await getDocs(notificationsQuery);
      const notificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
    };

    fetchNotifications();
  }, [currentUser]);

  const handleLogoutClick = async () => {
    await handleLogout();  // Ensure Firebase session is cleared before redirecting
    navigate('/login');
};


  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const getDashboardLink = () => {
    if (!currentUser || !currentUser.type) return '/';
    switch (currentUser.type.toLowerCase()) {
      case 'admin':
        return '/admin-dash';
      case 'manager':
        return '/manager-dashboard';
      case 'supervisor':
        return '/supervisor-dashboard';
      case 'spray_tech':
        return '/spray-tech-dashboard';
      default:
        return '/';
    }
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  const toggleNotifications = () => setShowNotifications(!showNotifications);

  const markAsRead = async (id) => {
    try {
      const notificationRef = doc(db, 'Notifications', id);
      await updateDoc(notificationRef, { read: true });
      setNotifications((prev) =>
        prev.map((n) => (n.id === id ? { ...n, read: true } : n))
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const clearNotification = async (id) => {
    try {
      const notificationRef = doc(db, 'Notifications', id);
      await deleteDoc(notificationRef);
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    } catch (error) {
      console.error('Error clearing notification:', error);
    }
  };

  return (
    <HeaderContainer>
      <LogoTitleContainer>
        <Link to={currentUser ? getDashboardLink() : '/'}>
          <Logo src={`${process.env.PUBLIC_URL}/rciLogo.png`} alt="RCI Logo" />
        </Link>
        <HeaderTitle>RCI Service NOW!</HeaderTitle>
      </LogoTitleContainer>
      <ButtonContainer>
  <WeatherWidget />
  <HeaderButton onClick={toggleLanguage}>
    {i18n.language === 'en' ? 'Español' : 'English'}
  </HeaderButton>
  {!currentUser ? (
    <Link to="/login">
        <HeaderButton>{t('Login')}</HeaderButton>
    </Link>
) : (
    <>
      <HeaderButton onClick={handleLogoutClick}>{t('Logout')}</HeaderButton>
      <IconButton onClick={toggleNotifications} aria-label="Notifications">
        <FaBell />
      </IconButton>
      {showNotifications && (
        <div style={{ position: 'absolute', top: '60px', right: '20px', zIndex: 100 }}>
          <ModalContent>
            <h3>Notifications</h3>
            {notifications.length === 0 ? (
              <p>No notifications.</p>
            ) : (
              <ul>
                {notifications.map((notification) => (
                  <li key={notification.id} style={{ marginBottom: '1rem' }}>
                    <p>{notification.message}</p>
                    {!notification.read && (
                      <button onClick={() => markAsRead(notification.id)}>Mark as Read</button>
                    )}
                    <button onClick={() => clearNotification(notification.id)}>Clear</button>
                  </li>
                ))}
              </ul>
            )}
          </ModalContent>
        </div>
      )}
      <IconButton onClick={toggleModal} aria-label="Request Assistance">
        <FaQuestionCircle />
      </IconButton>
    {/* 💬 Chat Widget Button */}
    <IconButton onClick={() => setIsChatOpen(!isChatOpen)} aria-label="Open AI Chat">
              <FaComments />
            </IconButton>

            {/* 🔹 Render Chat Widget when Open */}
            {isChatOpen && <ChatWidget />}
          </>
        )}
      </ButtonContainer>


{isModalOpen && currentUser && <EmailModal onClose={toggleModal} user={currentUser} />}

    </HeaderContainer>
  );
};


export default Header;
