import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUserDetails = async (authUser) => {
    if (!authUser?.uid) return null;
  
    try {
      const userDocRef = doc(db, 'Users', authUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        console.warn('No user document found in Firestore, using Firebase defaults.');
        return {
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName || '',
          type: 'user', // ✅ Default role if missing
          isAdmin: false,
        };
      }

      const userData = userDoc.data();
    return {
      uid: authUser.uid,
      email: authUser.email,
      displayName: authUser.displayName || `${userData.first_name || ''} ${userData.last_name || ''}`.trim(),
      type: userData.type?.toLowerCase().trim() || userData.role?.toLowerCase().trim() || 'user', // ✅ Normalize `type`
      isAdmin: !!userData.isAdmin,
      ...userData,
    };
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => console.log('Firebase persistence set to local'))
      .catch((error) => console.error('Error setting persistence:', error));

    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      console.log("🔥 Firebase Auth State Changed:", authUser);

      if (authUser) {
        const user = await fetchUserDetails(authUser);
        if (user) {
          handleLogin(user); // ✅ Calls handleLogin to ensure global state is updated
        }
      } else {
        handleLogout(false);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = (user) => {
    console.log('Manually setting logged-in user:', user);
    const standardizedUser = {
      ...user,
      type: user.type?.toLowerCase().trim() || user.role?.toLowerCase().trim() || 'user', // ✅ Ensure role is stored as `type`
    };
    setCurrentUser(standardizedUser);
    setIsLoggedIn(true);
    setIsAdmin(standardizedUser?.isAdmin || false);
  
    localStorage.setItem('currentUser', JSON.stringify(standardizedUser));
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('isAdmin', JSON.stringify(standardizedUser?.isAdmin || false));
  };

  const handleLogout = async (signOutFromFirebase = true) => {
    const auth = getAuth();
    try {
      if (signOutFromFirebase && auth.currentUser) {
        await signOut(auth);
      }
    } catch (error) {
      console.error('Error signing out:', error);
    }

    setCurrentUser(null);
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('isAdmin');
  };

  return (
    <AuthContext.Provider value={{ currentUser, isLoggedIn, isAdmin, handleLogin, handleLogout, loading }}>
      {!loading ? children : <p>Loading authentication...</p>}
    </AuthContext.Provider>
  );
};
