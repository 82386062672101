import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { getStorage, ref, getBytes } from 'firebase/storage';
import IncidentReportPDF from '../PDF Functions/IncidentReportPDF';
import Compressor from 'compressorjs';

const IncidentEmailSender = ({ selectedReport, onClose }) => {
  const [status, setStatus] = useState('Preparing email...');
  const [isSending, setIsSending] = useState(true);
  const [error, setError] = useState(null);

  const compressImage = (file) =>
    new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.4,
        maxWidth: 1024,
        maxHeight: 1024,
        convertSize: 200000,
        success: resolve,
        error: reject,
      });
    });

  const fetchAndConvertImages = async (imageUrls = []) => {
    const storage = getStorage();
    const base64Images = [];

    for (const url of imageUrls) {
      try {
        const pathMatch = url.match(/o\/(.+?)\?/);
        if (!pathMatch) continue;
        const path = decodeURIComponent(pathMatch[1]);
        const imageRef = ref(storage, path);
        const bytes = await getBytes(imageRef);
        const blob = new Blob([bytes], { type: 'image/jpeg' });
        const compressed = await compressImage(blob);

        const base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.readAsDataURL(compressed);
        });

        const name = path.split('/').pop() || `image-${Date.now()}.jpg`;
        base64Images.push({ name, base64 });
      } catch (err) {
        console.error('Error processing image:', err);
      }
    }

    return base64Images;
  };

  const sendEmail = async () => {
    try {
      setIsSending(true);
      setStatus('Generating PDF...');

      const pdfBlob = await pdf(<IncidentReportPDF reportData={selectedReport} />).toBlob();

      const pdfBase64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(pdfBlob);
      });

      setStatus('Processing images...');
      const imageUrls = selectedReport?.attachments?.imageURLs || [];
      const base64Images = await fetchAndConvertImages(imageUrls);

      setStatus('Sending email...');

      const emailData = {
        to: ['eporter@rotoloconsultants.com'],
        subject: `Incident Report - ${selectedReport.employeeName || 'Unnamed'}`,
        text: `An incident report has been submitted. Please see the attached PDF and photo documentation.`,
        html: `An incident report has been submitted. Please see the attached PDF and photo documentation.`,
        from: 'serviceupdate@rotoloconsultants.com',
        attachments: [
          {
            content: pdfBase64,
            filename: 'incident_report.pdf',
            type: 'application/pdf',
            disposition: 'attachment',
          },
          ...base64Images.map((img) => ({
            content: img.base64,
            filename: img.name,
            type: 'image/jpeg',
            disposition: 'attachment',
          })),
        ],
      };

      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailData }),
      });

      if (response.ok) {
        setStatus('Email sent successfully!');
      } else {
        throw new Error('Email send failed');
      }
    } catch (err) {
      console.error('Email sending error:', err);
      setError('Failed to send email. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (selectedReport) {
      sendEmail();
    }
  }, [selectedReport]);

  return (
    <div className="email-modal">
      <div className="email-modal-content">
        <h2>Sending Incident Report</h2>
        <p>{status}</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button className="secondary-button" onClick={onClose} disabled={isSending}>
          {isSending ? 'Sending...' : 'Close'}
        </button>
      </div>
    </div>
  );
};

export default IncidentEmailSender;
