import React, { useState, useEffect, useContext, useRef } from 'react';
import { db } from '../Firebase Functions/firebase';
import { collection, getDocs, doc, updateDoc, query, where, getDoc, orderBy, limit, runTransaction } from 'firebase/firestore';
import { AuthContext } from '../hooks/AuthProvider';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 

// Styled Components (same as before)
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  color: #296b1a;
`;

const SectionTitle = styled.h3`
  margin-top: 30px;
  color: #333;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  color: red;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: darkred;
  }
`;

const FormGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #296b1a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #217014;
  }
`;

const SuggestionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: white;
  position: absolute;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  width: 100%; /* Match input box width */
  top: calc(100% + 4px); /* Position just below the input box */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const AssignedVehiclesList = styled.div`
  margin-top: 20px;
`;

const AssignedVehicleItem = styled.div`
  position: relative;  /* Ensure relative positioning */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;


const OilChangeList = styled.div`
  margin-top: 20px;
`;

const OilChangeItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

const DefectsList = styled.ul`
  list-style: disc inside;
  padding-left: 1rem;
  margin: 0.5rem 0;
  color: #333;
  font-size: 0.95rem;
`;

const DefectItem = styled.li`
  margin-bottom: 0.5rem;
  line-height: 1.4;
`;

const VehicleDetails = styled.div`
  margin-top: 0.5rem;
`;

const DefectsSection = styled.div`
  margin-top: 1rem;
`;

const VehicleMaintenance = () => {
  const [vehicles, setVehicles] = useState([]);
  const [assignedVehicles, setAssignedVehicles] = useState([]);
  const [vehiclesNeedingOilChange, setVehiclesNeedingOilChange] = useState([]);
  const [vehicleDefects, setVehicleDefects] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const searchRef = useRef(null);
  const navigate = useNavigate(); 

  // Fetch all vehicles
  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const truckCollection = collection(db, 'Trucks');
        const truckSnapshot = await getDocs(truckCollection);
        const truckList = truckSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVehicles(truckList);
        filterVehiclesNeedingOilChange(truckList); // Check for vehicles needing oil change
      } catch (error) {
        console.error('Error fetching trucks:', error);
      }
    };

    fetchTrucks();
  }, []);

  // Fetch assigned vehicles and defects
  useEffect(() => {
    if (!currentUser) return;

    const fetchAssignedVehicles = async () => {
      try {
        const trucksQuery = query(
          collection(db, 'Trucks'),
          where('assignedTo', 'array-contains', currentUser.email)
        );
        const snapshot = await getDocs(trucksQuery);
        const assigned = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAssignedVehicles(assigned);

        fetchDefects(assigned);
        filterVehiclesNeedingOilChange(assigned);
      } catch (error) {
        console.error('Error fetching assigned vehicles:', error);
      }
    };

    fetchAssignedVehicles();
  }, [currentUser]);

  // Fetch vehicle defects
  const fetchDefects = async (assignedVehicles) => {
    try {
      const defectsData = {};
  
      for (const vehicle of assignedVehicles) {
        const inspectionsQuery = query(
          collection(db, "vehicleInspections"),
          where("truckId", "==", vehicle.id),
          orderBy("timestamp", "desc"),
          limit(1)
        );
        const inspectionsSnapshot = await getDocs(inspectionsQuery);
  
        if (!inspectionsSnapshot.empty) {
          const latestInspection = inspectionsSnapshot.docs[0].data();
          const truckChecklist = latestInspection.checklist || {};
          const trailerChecklist = latestInspection.trailerChecklist || {};

           // Extract "Other" values properly
           const otherTruckDefect = truckChecklist["Other"] ? truckChecklist["Other"] : "";
           const otherTrailerDefect = trailerChecklist["Other"] ? trailerChecklist["Other"] : "";
   
           defectsData[vehicle.id] = {
             truckDefects: Object.keys(truckChecklist).filter((key) => truckChecklist[key] === true),
             trailerDefects: Object.keys(trailerChecklist).filter((key) => trailerChecklist[key] === true),
             otherTruck: otherTruckDefect, // Correctly retrieve user-entered "Other" defect
             otherTrailer: otherTrailerDefect, // Correctly retrieve user-entered "Other" trailer defect
           };
         } else {
           defectsData[vehicle.id] = { truckDefects: [], trailerDefects: [], otherTruck: "", otherTrailer: "" };
         }
       }
   
       console.log("Defects Data:", defectsData); // Check the data being fetched and passed to the state
       setVehicleDefects(defectsData);
     } catch (error) {
       console.error("Error fetching defects:", error);
     }
   };
   
   
   
   
   
   
   
   const unassignVehicle = async (vehicleId) => {
     const confirmUnassign = window.confirm("Are you sure you want to unassign this truck?");
     if (!confirmUnassign) return;
   
     try {
       const vehicleRef = doc(db, 'Trucks', vehicleId);
       const vehicleDoc = await getDoc(vehicleRef);
   
       if (!vehicleDoc.exists()) {
         alert('Vehicle not found.');
         return;
       }
   
       let assignedUsers = vehicleDoc.data().assignedTo || []; // Default to empty array
   
       if (!Array.isArray(assignedUsers)) {
         assignedUsers = [assignedUsers]; // Convert to array if needed
       }
   
       // Remove the current user from the assignedUsers array
       const updatedAssignedUsers = assignedUsers.filter(email => email !== currentUser.email);
   
       // Only set to null if there are no users left
       await updateDoc(vehicleRef, { assignedTo: updatedAssignedUsers.length > 0 ? updatedAssignedUsers : null });
   
       alert("Truck unassigned successfully!");
   
       // Remove the vehicle from the assignedVehicles state
       setAssignedVehicles(prev => prev.filter(vehicle => vehicle.id !== vehicleId));
     } catch (error) {
       console.error("Error unassigning truck:", error);
       alert("Failed to unassign truck.");
     }
   };
   
   
 
   
 
 const filterVehiclesNeedingOilChange = (vehiclesList) => {
   const needingOilChange = vehiclesList.filter((vehicle) => {
     const currentMileage = parseFloat(vehicle.mileage || 0);
     const lastOilChangeMileage = parseFloat(vehicle.lastOilChange || 0);
     const initialMileage = parseFloat(vehicle.initialMileage || 0); // Store first recorded mileage
 
     // If lastOilChange exists, check against that; otherwise, use initialMileage
     const milesSinceLastOilChange = lastOilChangeMileage ? currentMileage - lastOilChangeMileage : currentMileage - initialMileage;
 
     return milesSinceLastOilChange >= 7000;
   });
 
   setVehiclesNeedingOilChange(needingOilChange);
 };
 
   
 
   useEffect(() => {
     const handleClickOutside = (event) => {
       if (searchRef.current && !searchRef.current.contains(event.target)) {
         setShowSuggestions(false);
       }
     };
 
     document.addEventListener('mousedown', handleClickOutside);
     return () => {
       document.removeEventListener('mousedown', handleClickOutside);
     };
   }, []);
 
   const handleSearchChange = (e) => {
     const value = e.target.value;
     setSearchTerm(value);
   
     if (value.trim() === '') {
       setFilteredVehicles([]); // Clear suggestions if input is empty
       return;
     }
   
     const filtered = vehicles.filter((vehicle) => {
       const name = vehicle.vehicleName?.toLowerCase() || '';
       return name.includes(value.toLowerCase()); // Allow all vehicles to appear
     });
   
     setFilteredVehicles(filtered);
   };
   
   
   
 
   const assignVehicleToUser = async () => {
     if (!selectedVehicle) {
       alert('Please select a vehicle.');
       return;
     }
   
     try {
       const vehicleRef = doc(db, 'Trucks', selectedVehicle.id);
       await runTransaction(db, async (transaction) => {
         const vehicleDoc = await transaction.get(vehicleRef);
   
         if (!vehicleDoc.exists()) {
           throw new Error('Vehicle not found.');
         }
   
         let assignedUsers = vehicleDoc.data().assignedTo || [];
   
         if (!Array.isArray(assignedUsers)) {
           assignedUsers = [assignedUsers];
         }
   
         if (!assignedUsers.includes(currentUser.email)) {
           assignedUsers.push(currentUser.email);
         }
   
         transaction.update(vehicleRef, { assignedTo: assignedUsers });
       });
   
       alert(`Vehicle ${selectedVehicle.vehicleName} assigned to you.`);
       setAssignedVehicles((prev) => [
         ...prev,
         { ...selectedVehicle, assignedTo: [...selectedVehicle.assignedTo, currentUser.email] },
       ]);
       setSearchTerm('');
       setSelectedVehicle(null);
     } catch (error) {
       console.error('Error assigning vehicle:', error);
       alert('Failed to assign vehicle.');
     }
   };
   
   
   
   
 
   const updateLastOilChange = async (vehicle) => {
     const confirmChange = window.confirm(
       `Are you sure you want to mark ${vehicle.vehicleName} as serviced?`
     );
     if (!confirmChange) return;
   
     try {
       const vehicleRef = doc(db, 'Trucks', vehicle.id);
       await updateDoc(vehicleRef, { lastOilChange: vehicle.mileage });
   
       alert(`${vehicle.vehicleName} has been serviced.`);
       
       // Remove from the list of vehicles needing oil change
       setVehiclesNeedingOilChange(prev =>
         prev.filter((v) => v.id !== vehicle.id)
       );
     } catch (error) {
       console.error('Error updating oil change:', error);
       alert('Failed to update oil change status.');
     }
   };
   
 
   return (
     <Container>
       <Title>Your Assigned Vehicles</Title>
 
       <AssignedVehiclesList>
   {assignedVehicles.length === 0 ? (
     <p>You have no vehicles assigned.</p>
   ) : (
     assignedVehicles.map((vehicle) => (
       <AssignedVehicleItem key={vehicle.id}>
         <RemoveButton onClick={() => unassignVehicle(vehicle.id)}>✖</RemoveButton>
         <p>
           <strong>Truck Number:</strong> {vehicle.vehicleName}
         </p>
         <VehicleDetails>
           <p>Mileage: {vehicle.mileage}</p>
 
          {/* Truck Defects */}
          {vehicleDefects[vehicle.id] && vehicleDefects[vehicle.id].truckDefects.length > 0 && (
   <DefectsSection>
     <p><strong>Truck Defects:</strong></p>
     <DefectsList>
       {vehicleDefects[vehicle.id].truckDefects.map((defect, index) => (
         <DefectItem key={index}>{defect}</DefectItem>
       ))}
 
       {/* Display "Other" defect if there is input */}
       {vehicleDefects[vehicle.id].otherTruck &&
         vehicleDefects[vehicle.id].otherTruck.trim() !== "" && (
           <DefectItem><strong>Truck Other:</strong> {vehicleDefects[vehicle.id].otherTruck}</DefectItem>
       )}
     </DefectsList>
   </DefectsSection>
 )}
 
 {vehicleDefects[vehicle.id] && vehicleDefects[vehicle.id].trailerDefects.length > 0 && (
   <DefectsSection>
     <p><strong>Trailer Defects:</strong></p>
     <DefectsList>
       {vehicleDefects[vehicle.id].trailerDefects.map((defect, index) => (
         <DefectItem key={index}>{defect}</DefectItem>
       ))}
 
       {/* Display "Other" defect if there is input */}
       {vehicleDefects[vehicle.id].otherTrailer &&
         vehicleDefects[vehicle.id].otherTrailer.trim() !== "" && (
           <DefectItem><strong>Trailer Other:</strong> {vehicleDefects[vehicle.id].otherTrailer}</DefectItem>
       )}
     </DefectsList>
   </DefectsSection>
 )}
 
 
 
 
 
 
 
 
 
 
 
 
 
 
         </VehicleDetails>
 
         <Button onClick={() => navigate('/truck-checklist', { state: { truckId: vehicle.id } })}>
           Trip Inspection
         </Button>
         <Button onClick={() => navigate('/manager-inspection-history', { state: { truckId: vehicle.id } })}>
           Inspection History
         </Button>
       </AssignedVehicleItem>
     ))
   )}
 </AssignedVehiclesList>
 
 <SectionTitle>Assign a Vehicle</SectionTitle>
 <FormGroup style={{ position: 'relative' }}>
   <Label>Search for a Vehicle</Label>
   <Input
     type="text"
     placeholder="Type vehicle name or ID"
     value={searchTerm}
     onChange={handleSearchChange}
     onFocus={() => setShowSuggestions(true)}
     onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
   />
   
   {showSuggestions && filteredVehicles.length > 0 && (
     <SuggestionsList>
       {filteredVehicles.map((vehicle) => (
         <SuggestionItem
           key={vehicle.id}
           onClick={() => {
             setSelectedVehicle(vehicle);
             setSearchTerm(vehicle.vehicleName || `Truck ${vehicle.id}`);
             setShowSuggestions(false);
           }}
         >
           {vehicle.vehicleName || `Truck ${vehicle.id}`}  
           {vehicle.assignedTo && Array.isArray(vehicle.assignedTo) && vehicle.assignedTo.length > 0 && (
             <span> (Assigned to {vehicle.assignedTo.length} users)</span>
           )}
         </SuggestionItem>
       ))}
     </SuggestionsList>
   )}
 </FormGroup>
 
 
 
 
 
       {selectedVehicle && (
   <Button onClick={assignVehicleToUser}>Assign Selected Vehicle</Button>
 )}
 
 
 <SectionTitle>Vehicles Needing Oil Change</SectionTitle>
 <OilChangeList>
   {vehiclesNeedingOilChange.length === 0 ? (
     <p>No vehicles need an oil change at the moment.</p>
   ) : (
     vehiclesNeedingOilChange.map((vehicle) => (
       <OilChangeItem key={vehicle.id}>
         <p>
           <strong>{vehicle.vehicleName}</strong>
         </p>
         <p>Mileage: {vehicle.mileage}</p>
         <p>Last Oil Change Mileage: {vehicle.lastOilChange || 'N/A'}</p>
         <Button onClick={() => updateLastOilChange(vehicle)}>Mark Serviced</Button>
       </OilChangeItem>
     ))
   )}
 </OilChangeList>
 
     </Container>
   );
 };
 
 export default VehicleMaintenance;