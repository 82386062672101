import React, { useState, useEffect } from "react";
import { db } from "../Firebase Functions/firebase";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { useLocation } from "react-router-dom"; // Import useLocation
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  color: #296b1a;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const SuggestionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: white;
  position: absolute;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const InspectionList = styled.div`
  margin-top: 20px;
`;

const InspectionItem = styled.div`
  background: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChecklistSection = styled.div`
  margin-top: 10px;
`;

const ChecklistItem = styled.p`
  margin: 2px 0;
`;

const InspectionHistory = () => {
  const location = useLocation(); // Access location from React Router
  const [truckId, setTruckId] = useState(location.state?.truckId || ""); // Set initial truckId from location.state
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [trucks, setTrucks] = useState([]); // List of all trucks
  const [filteredTrucks, setFilteredTrucks] = useState([]); // Search results
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Fetch trucks from Firestore on component mount
  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const truckCollection = collection(db, "Trucks");
        const truckSnapshot = await getDocs(truckCollection);
        const truckList = truckSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTrucks(truckList);
      } catch (error) {
        console.error("Error fetching trucks:", error);
      }
    };

    fetchTrucks();
  }, []);

  // Handle Search Query
  const handleSearch = async () => {
    if (!truckId.trim()) {
      setError("Please enter a truck ID.");
      return;
    }
  
    setLoading(true);
    setError("");
  
    try {
      // First, find the truck with the entered truckId (vehicleName or another identifier)
      const truckQuery = query(collection(db, "Trucks"), where("vehicleName", "==", truckId));
      const truckSnapshot = await getDocs(truckQuery);
  
      if (truckSnapshot.empty) {
        setError("No matching truck found.");
        setInspections([]);
        setLoading(false);
        return;
      }
  
      // Get the first matching truck's samsara_id
      const truckData = truckSnapshot.docs[0].data();
      const samsaraId = truckData.samsara_id;
  
      if (!samsaraId) {
        setError("This truck does not have a Samsara ID.");
        setInspections([]);
        setLoading(false);
        return;
      }
  
      // Now fetch inspections where truckId matches samsara_id
      const inspectionsQuery = query(
        collection(db, "vehicleInspections"),
        where("truckId", "==", samsaraId),
        orderBy("timestamp", "desc")
      );
  
      const snapshot = await getDocs(inspectionsQuery);
  
      if (snapshot.empty) {
        setError("No inspections found for this truck.");
        setInspections([]);
      } else {
        const inspectionsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInspections(inspectionsList);
      }
    } catch (error) {
      console.error("Error fetching inspection history:", error);
      setError("Failed to fetch inspection data.");
    }
  
    setLoading(false);
  };
  

  // Handle user typing in the search box
  const handleTruckSearchChange = (e) => {
    const value = e.target.value;
    setTruckId(value);

    if (value.trim() === "") {
      setFilteredTrucks([]);
      setShowSuggestions(false);
    } else {
      const filtered = trucks.filter((truck) =>
        truck.vehicleName?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTrucks(filtered);
      setShowSuggestions(true);
    }
  };

  // Handle user selecting a truck from suggestions
  const handleTruckSelect = (truck) => {
    setTruckId(truck.id);
    setShowSuggestions(false);
  };

  return (
    <Container>
      <Title>Inspection History</Title>

      {/* Truck ID Search with Auto-Suggest */}
      <SearchContainer>
        <SearchBar
          type="text"
          placeholder="Enter Truck ID or Name"
          value={truckId}
          onChange={handleTruckSearchChange}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 150)}
        />
        {showSuggestions && filteredTrucks.length > 0 && (
          <SuggestionsList>
            {filteredTrucks.map((truck) => (
              <SuggestionItem
                key={truck.id}
                onClick={() => handleTruckSelect(truck)}
              >
                {truck.vehicleName || `Truck ${truck.id}`}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
      </SearchContainer>

      <button onClick={handleSearch} disabled={loading}>
        {loading ? "Searching..." : "Search"}
      </button>

      {/* Error Message */}
      {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}

      {/* Inspection Results */}
      <InspectionList>
        {inspections.length > 0 ? (
          inspections.map((inspection) => (
            <InspectionItem key={inspection.id}>
              <p><strong>Date:</strong> {inspection.date} at {inspection.time}</p>
              <p><strong>Inspection Type:</strong> {inspection.inspectionType}</p>
              <p><strong>Odometer:</strong> {inspection.odometer} miles</p>
              <p><strong>Comments:</strong> {inspection.comments || "None"}</p>

              {/* Truck Checklist */}
              {inspection.checklist && (
                <ChecklistSection>
                  <p><strong>Truck Checklist:</strong></p>
                  {Object.keys(inspection.checklist).map((key, index) => {
                    if (key === "Other" && typeof inspection.checklist["Other"] === "string" && inspection.checklist["Other"].trim() !== "") {
                      return (
                        <ChecklistItem key={index}>
                          ✅ <strong>Other:</strong> {inspection.checklist["Other"]}
                        </ChecklistItem>
                      );
                    }
                    return inspection.checklist[key] === true ? (
                      <ChecklistItem key={index}>✅ {key}</ChecklistItem>
                    ) : null;
                  })}
                </ChecklistSection>
              )}

              {/* Trailer Checklist */}
              {inspection.trailerChecklist && (
                <ChecklistSection>
                  <p><strong>Trailer Checklist:</strong></p>
                  {Object.keys(inspection.trailerChecklist).map((key, index) => {
                    if (key === "Other" && typeof inspection.trailerChecklist["Other"] === "string" && inspection.trailerChecklist["Other"].trim() !== "") {
                      return (
                        <ChecklistItem key={index}>
                          ✅ <strong>Other:</strong> {inspection.trailerChecklist["Other"]}
                        </ChecklistItem>
                      );
                    }

                    if (key === "Other" && (inspection.trailerChecklist[key] === true || inspection.trailerChecklist[key] === false)) {
                      return null;
                    }

                    return inspection.trailerChecklist[key] === true ? (
                      <ChecklistItem key={index}>✅ {key}</ChecklistItem>
                    ) : null;
                  })}
                </ChecklistSection>
              )}
            </InspectionItem>
          ))
        ) : (
          !loading && !error && <p style={{ textAlign: "center" }}>No inspections found.</p>
        )}
      </InspectionList>
    </Container>
  );
};

export default InspectionHistory;
