import React, { useEffect, useState, useContext } from 'react';
import { db } from './Firebase Functions/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { AuthContext } from './hooks/AuthProvider';
import styled from 'styled-components';

// Main container styling
const Container = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
`;

// Title styling
const StyledTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

// Punch List card container
const PunchListCard = styled.div`
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
`;

// Task list styling
const TaskList = styled.ul`
  list-style: none;
  padding: 0;
`;

// Task item styling
const TaskItem = styled.li`
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 4px;
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

// Task note and image styling
const TaskNote = styled.p`
  font-weight: bold;
  margin: 0.5rem 0;
`;

// Task image styling
const TaskImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const PunchListViewer = () => {
  const { currentUser } = useContext(AuthContext); // Access the current user from global context
  const [openPunchLists, setOpenPunchLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  useEffect(() => {
    const fetchPunchLists = async () => {
      // Check if currentUser or currentUser.email is valid
      if (!currentUser || !currentUser.email) {
        setError('User email is missing.');
        setLoading(false);
        return;
      }
  
      try {
        // Query Properties to find properties where the user is a manager
        const propertiesQuery = query(
          collection(db, 'Properties')
        );
  
        const propertiesSnapshot = await getDocs(propertiesQuery);
  
        if (propertiesSnapshot.empty) {
          setError('No properties found where you are listed as a manager.');
          setLoading(false);
          return;
        }
  
        // Process properties and filter by email presence in the AM Email List
        const propertyIds = [];
        propertiesSnapshot.docs.forEach(doc => {
          const propertyData = doc.data();
  
          // Split the AM Email List string into an array of emails, trimming each email and converting to lowercase
          const amEmailList = propertyData['AM Email List']
            ? propertyData['AM Email List'].split(',').map(email => email.trim().toLowerCase()) // Split, trim, and convert to lowercase
            : [];
  
          // Normalize the currentUser email to lowercase and trim
          const normalizedCurrentEmail = currentUser.email.trim().toLowerCase();
  
          console.log("AM Email List for property:", amEmailList);  // Log to verify
          console.log("Normalized Current User Email:", normalizedCurrentEmail);  // Log to verify
  
          // Check if normalized currentUser.email is in the AM Email List
          if (amEmailList.includes(normalizedCurrentEmail)) {
            const propertyName = propertyData['Property Name']; // Use Property Name here instead of propertyId
            if (propertyName) {
              propertyIds.push(propertyName); // Push the Property Name to be used for punch list query
            } else {
              console.log("No valid propertyName found for this property:", propertyData);
            }
          }
        });
  
        console.log("Final Property IDs for Query:", propertyIds);  // Verify the property IDs
  
        if (propertyIds.length === 0) {
          setError('No properties found where you are listed as a manager.');
          setLoading(false);
          return;
        }
  
        // Now fetch the punch lists for each propertyName
        const punchLists = [];
  
        // Ensure propertyIds is not empty before querying tasks
        if (propertyIds.length > 0) {
          for (const propertyName of propertyIds) {
            console.log("Fetching tasks for propertyName:", propertyName);  // Log the propertyName being queried
  
            const punchListQuery = query(
              collection(db, 'tasks'),
              where('propertyName', '==', propertyName),  // Adjust query to match 'propertyName' in tasks collection
              where('completed', '==', false)
            );
  
            const querySnapshot = await getDocs(punchListQuery);
  
            querySnapshot.docs.forEach((doc) => {
              punchLists.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          }
  
          setOpenPunchLists(punchLists);
        } else {
          setError('No valid property IDs found for your email.');
        }
  
      } catch (error) {
        setError('Error fetching punch lists.');
        console.error("Error fetching punch lists:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPunchLists();
  }, [currentUser]);

  if (loading) {
    return <p>Loading punch lists...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container>
      <StyledTitle>Open Punch Lists for Properties You Manage</StyledTitle>
      {openPunchLists.length === 0 ? (
        <p>No open punch lists found for properties you manage.</p>
      ) : (
        <div>
          {openPunchLists.map((punchList) => (
            <PunchListCard key={punchList.id}>
              <h3>{punchList.propertyName}</h3>
              <p><strong>Assigned by:</strong> {punchList.assignedBy.name}</p>
              <p><strong>Assigned To:</strong> {punchList.assignedUserName}</p>
              <TaskList>
                {punchList.tasks.map((task, index) => (
                  <TaskItem key={index}>
                    <TaskNote><strong>Task:</strong> {task.note}</TaskNote>
                    {task.type === 'image' && <TaskImage src={task.url} alt={`Task ${index}`} />}
                  </TaskItem>
                ))}
              </TaskList>
            </PunchListCard>
          ))}
        </div>
      )}
    </Container>
  );
};

export default PunchListViewer;
