export const sendEmailNotification = async ({ to, subject, text, html }) => {
    try {
      const emailData = { to, subject, text, html };
  
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailData })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Unknown error while sending email');
      }
  
      return true; // success
    } catch (error) {
      console.error('❌ Email sending failed:', error);
      throw error;
    }
  };
  