import React, { useEffect, useState, useContext } from "react";
import { db } from "../Firebase Functions/firebase";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import styled from "styled-components";
import { AuthContext } from "../hooks/AuthProvider";

const Container = styled.div`
  padding: 1rem;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background: #f9f9f9;
`;


const PendingApplications = () => {
  const { currentUser } = useContext(AuthContext); // ✅ Use useContext to match other components
  const [pendingForms, setPendingForms] = useState([]);
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(true);

  console.log("🔹 TechChemAppList Mounted - Current User:", currentUser);

  useEffect(() => {
    if (!currentUser) {
      console.warn("⚠️ User is still loading, waiting...");
      return;
    }

    console.log("🔹 Fetching Firestore User Details for UID:", currentUser.uid);

    const fetchUserDetails = async () => {
      try {
        const userRef = doc(db, "Users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log("✅ User Data from Firestore:", userData);

          const firstName = userData.first_name || "";
          const lastName = userData.last_name || "";
          const formattedFullName = `${firstName} ${lastName}`.trim();

          setFullName(formattedFullName);
          console.log("✅ User Full Name (from Firestore):", formattedFullName);
        } else {
          console.warn("⚠️ User document not found in Firestore.");
        }
      } catch (error) {
        console.error("❌ Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [currentUser]); // ✅ Wait until currentUser is available

  useEffect(() => {
    if (!fullName || loading) {
      console.warn("⚠️ Full Name is empty or still loading, skipping pending forms fetch.");
      return;
    }

    console.log("🔹 Fetching Pending Forms for:", fullName);

    const fetchPendingForms = async () => {
      try {
        const q = query(
          collection(db, "ChemAppForms"),
          where("status", "==", "pending"),
          where("applicator", "==", fullName)
        );

        const querySnapshot = await getDocs(q);
        const forms = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("✅ Fetched Pending Applications:", forms);
        setPendingForms(forms);
      } catch (error) {
        console.error("❌ Error fetching pending applications:", error);
      }
    };

    fetchPendingForms();
  }, [fullName, loading]); // ✅ Wait until fullName is set and loading is false

  if (!currentUser || loading) return <p>Loading...</p>; // ✅ Prevent rendering too early

  return (
    <Container>
      <h2>Pending Chemical Applications</h2>
      {pendingForms.length === 0 ? (
        <p>No pending applications found.</p>
      ) : (
        pendingForms.map((form) => (
          <Card key={form.id}>
            <h3>{form.project}</h3>
            <p><strong>Applicator:</strong> {form.applicator}</p>
            <p><strong>Date:</strong> {form.applicationDate}</p>
            <p><strong>Location:</strong> {form.city}, {form.state}</p>
            <p><strong>Status:</strong> {form.status}</p>
          </Card>
        ))
      )}
    </Container>
  );
};

export default PendingApplications;
