import React, { useEffect, useState, useContext } from "react";
import { db } from "../Firebase Functions/firebase";
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";
import styled from "styled-components";
import { AuthContext } from "../hooks/AuthProvider";
import { useNavigate } from "react-router-dom"; // ✅ Import navigate
import QuickbaseUpdate from "../Quickbase Functions/QBChemForm";
import { useParams } from "react-router-dom";
import { pdf } from '@react-pdf/renderer';
import { ChemicalApplicationPDF } from "../PDF Functions/ChemAppPDF";


const Container = styled.div`
  padding: 1rem;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  background: #f9f9f9;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background: #007820;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: #005c18;
  }
`;

const ManagerChemAppList = () => {
  const { currentUser } = useContext(AuthContext);
  const [formData, setFormData] = useState(null);
  const [pendingForms, setPendingForms] = useState([]);
  const [fullName, setFullName] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [newRecord, setNewRecord] = useState({
    applicationDate: '',
    applicator: '',
    ownerOperatorNumber: '',
    applicatorId: '',
    project: '',
    address: '',
    city: '',
    state: '',
    temperature: '',
    wind: '',
    areaTreated: '',
    areaUnit: '',
    usedRestrictedPesticide: '',
    chemicals: [
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
    ],
    checkboxes: {
      turfFertilizer: false,
      turfPostEmerge: false,
    },
    notes: '',
  });
  
  const navigate = useNavigate(); // ✅ Initialize navigate

  console.log("🔹 ManagerChemAppList Mounted - Current User:", currentUser);

  // ✅ Fetch the user's full name from Firestore
  useEffect(() => {
    if (!currentUser) return;

    console.log("🔹 Fetching Firestore User Details for UID:", currentUser.uid);

    const fetchUserDetails = async () => {
      try {
        const userRef = doc(db, "Users", currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log("✅ User Data from Firestore:", userData);

          const formattedFullName = `${userData.first_name} ${userData.last_name}`.trim();
          setFullName(formattedFullName);
          console.log("✅ User Full Name:", formattedFullName);
        } else {
          console.warn("⚠️ User document not found.");
        }
      } catch (error) {
        console.error("❌ Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [currentUser]);


  // ✅ Fetch pending chemical applications
  useEffect(() => {
    if (!fullName || loading) return;

    console.log("🔹 Fetching Pending Forms for:", fullName);

    const fetchPendingForms = async () => {
      try {
        const q = query(
          collection(db, "ChemAppForms"),
          where("status", "==", "pending"),
          where("applicator", "==", fullName)
        );

        const querySnapshot = await getDocs(q);
        const forms = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("✅ Fetched Pending Applications:", forms);
        setPendingForms(forms);
      } catch (error) {
        console.error("❌ Error fetching pending applications:", error);
      }
    };

    fetchPendingForms();
  }, [fullName, loading]);

  if (!currentUser || loading) return <p>Loading...</p>;

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract Base64 string
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const generatePdfBlob = async () => {
      return await pdf(<ChemicalApplicationPDF recordData={newRecord} />).toBlob();
    };
  

    const handleApproval = async (form) => {
      try {
        console.log("🔹 Approving form:", form);
    
        // Ensure form data is valid
        if (!form || !form.id) {
          console.error("❌ Form data is missing or invalid:", form);
          alert("Error: Form data is missing.");
          return;
        }
    
        // Generate PDF Blob
        const blob = await pdf(<ChemicalApplicationPDF recordData={form} />).toBlob();
        console.log("✅ Generated PDF Blob with size:", blob.size, "bytes");
    
        // Convert Blob to Base64
        const pdfBase64 = await convertBlobToBase64(blob);
        console.log("✅ Converted PDF to Base64 with length:", pdfBase64.length, "characters");
    
        // Prepare data fields for Quickbase
        const fields = {
          applicator: form.applicator,
          projectName: form.project,
          applicationDate: form.applicationDate,
          notes: form.notes,
          pdfBase64: pdfBase64, // Base64-encoded PDF
        };
    
        console.log("🔹 Sending data to Quickbase:", fields);
    
        // Call QuickbaseUpdate function
        const result = await QuickbaseUpdate({
          tableId: "brt8jnu22", // Replace with your actual Quickbase table ID
          fields,
        });
    
        console.log("✅ Quickbase Update Success:", result);
    
        // Update Firestore document status to 'approved'
        const docRef = doc(db, "ChemAppForms", form.id);
        await updateDoc(docRef, { status: "approved" });
    
        console.log("✅ Firestore updated to 'approved' status!");
    
        setSuccessModalVisible(true); // Show success message
      } catch (error) {
        console.error("❌ Error during approval:", error);
        alert("An error occurred. Please try again.");
      }
    };
    
    
      
      
  

  return (
    <Container>
      <h2>Pending Chemical Applications</h2>
      {pendingForms.length === 0 ? (
        <p>No pending applications found.</p>
      ) : (
        pendingForms.map((form) => (
          <Card key={form.id}>
            <h3>{form.project}</h3>
            <p><strong>Applicator:</strong> {form.applicator}</p>
            <p><strong>Date:</strong> {form.applicationDate}</p>
            <p><strong>Location:</strong> {form.city}, {form.state}</p>
            <p><strong>Status:</strong> {form.status}</p>

            {/* 🔥 Navigate to Edit Page with Document ID */}
            <Button onClick={() => navigate(`/edit-chemical-form/${form.id}`, { state: { form } })}>
              Edit
            </Button>
            <Button type="button" onClick={() => handleApproval(form)}>
             Approve
            </Button>



          </Card>
        ))
      )}
    </Container>
  );
};

export default ManagerChemAppList;
