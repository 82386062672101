import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth'
import { useParams , useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import PropertyList from '../QB Lists/PropertyList';
import { format } from 'date-fns';
import { doc, setDoc, collection, updateDoc, getDoc, serverTimestamp, query, where, getDocs} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../Firebase Functions/firebase';
import AMEmailSender from '../Email Functions/Email Sender/AMEmailSender';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin-top: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color:rgb(66, 100, 80);
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    font-size: 18px;
  }
`;

const StyledImageUpload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;

  input[type='file'] {
    padding: 0.5rem;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
  }

  .image-preview {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  img {
    height: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ServiceCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

const CheckboxLabel = styled.label`
  margin-left: 0.5rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ImagePreview = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
    width: 100%;
  }
`;

const RemoveButton = styled.button.attrs({ type: 'button' })`
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.5rem;
    margin-top: 0.5rem;
    width: 100%;
    margin-left: 0;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
`;

const services = [
  'Turf Mow', 'String Trim', 'Edging', 'Blow/Cleanup', 'Irrigation Monitoring and Control',
  'Mulch Install', 'Annuals Install', 'Tree Pruning', 'Palm Pruning', 'Weed Management', 'Crack and Crevice', 'Litter/Debris Removal',
  'Remediation', 'Seasonal Color Watering', 'Shrub Pruning', 'Pond Maintenance', 'Other'
];

const JobForm = ({ initialData: propInitialData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: jobIdFromParams } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobId, setJobId] = useState(jobIdFromParams || null);

  // ✅ Ensure `initialData` is only used when editing
  const initialData = propInitialData || location.state?.initialData || null;

  // ✅ Always start with a fresh form unless explicitly editing
  const [formData, setFormData] = useState(() => {
      if (initialData) {
          console.log("Editing existing report:", initialData);
          return initialData; // ✅ Editing a saved report
      }

      // ✅ If no initialData, create a fresh form (DO NOT load localStorage)
      console.log("Starting a new blank report.");
      return {
          supervisorName: '',
          property: '',
          serviceTypes: [],
          customServiceDescription: '',
          date: format(new Date(), 'yyyy-MM-dd'),
          notes: '',
          image: null,
          secondImage: null,
          thirdImage: null,
          fourthImage: null,
          fifthImage: null,
          customPropertyName: '',
          truckNumber: '', 
          truckDriver: ''
      };
  });

  const [error, setError] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isEmailSenderOpen, setEmailSenderOpen] = useState(false);
  const [submittedJobData, setSubmittedJobData] = useState(null);
  const isEditing = !!initialData;

  // ✅ Load `initialData` ONLY if editing
  useEffect(() => {
      if (initialData) {
          console.log("Loading report from navigation:", initialData);
          setFormData(initialData);
      }
  }, [initialData]);





  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      
      if (currentUser) {
        const userEmail = currentUser.email;
        setUserEmail(userEmail); // This sets the createdBy field
        
        try {
          // Query Users collection where email matches current user's email
          const usersRef = collection(db, 'Users');
          const q = query(usersRef, where("email", "==", userEmail));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
            // Get the first matching document
            const userData = querySnapshot.docs[0].data();
            const userType = userData.type;
            console.log('User type found:', userType); // Debug log
            
            // Set both supervisorName and userType in formData
            const fullName = `${userData.first_name} ${userData.last_name}`.trim();
            setFormData(prevState => ({
              ...prevState,
              supervisorName: fullName,
              userType: userType // This will eventually go to the Services collection
            }));
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked :
                type === 'file' ? files[0] :
                value
    }));
};


  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      serviceTypes: checked
        ? [...prevState.serviceTypes, value]
        : prevState.serviceTypes.filter(service => service !== value)
    }));
  };

  const handleRemoveImage = (imageName) => {
    setFormData(prevState => ({ ...prevState, [imageName]: null }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length > 5) {
        alert("You can only upload up to 5 images at once");
        return;
    }

    const imageFields = ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'];

    setFormData(prevState => {
        const newFormData = { ...prevState };

        // Find the next available slot for images
        let imageIndex = 0;
        for (const field of imageFields) {
            if (!newFormData[field] && imageIndex < files.length) {
                newFormData[field] = files[imageIndex];
                imageIndex++;
            }
        }

        return newFormData;
    });
};


const handleSaveAndClose = async () => {
  try {
      // Create a copy of formData to modify before saving
      const saveData = { ...formData };

      // ✅ Convert File objects to URLs before saving
      ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].forEach(imageField => {
          if (saveData[imageField] instanceof File) {
              saveData[imageField] = URL.createObjectURL(saveData[imageField]);
          }
      });

      // ✅ Save to localStorage
      localStorage.setItem('savedJobForm', JSON.stringify(saveData));
      alert("Report saved! You can return to complete it later.");

      // ✅ Get the authenticated user
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
          console.error("No authenticated user found, redirecting to home.");
          navigate('/', { replace: true });
          return;
      }

      const userEmail = currentUser.email;

      // ✅ Fetch user type from Firestore
      const usersRef = collection(db, 'Users');
      const q = query(usersRef, where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
          console.error("User type not found, redirecting to default page.");
          navigate('/', { replace: true });
          return;
      }

      const userData = querySnapshot.docs[0].data();
      const userType = userData.type.toLowerCase();

      // ✅ Determine the correct dashboard route
      const dashboardRoutes = {
          admin: '/admin-dash',
          manager: '/manager-dashboard',
          supervisor: '/supervisor-dashboard',
          spray_tech: '/spray-tech-dashboard'
      };

      const dashboardRoute = dashboardRoutes[userType] || '/';
      
      // ✅ Navigate to the correct dashboard
      navigate(dashboardRoute, { replace: true });

  } catch (error) {
      console.error("Error saving report:", error);
      alert("An error occurred while saving the report. Please try again.");
  }
};




const handleSubmit = async (e) => {
  e.preventDefault();
  
  // Prevent multiple submissions
  if (isSubmitting) return;  // Do nothing if already submitting
  
  setIsSubmitting(true);  // Disable the form during submission
  setError('');
  setSuccessMessage('');

  if (!formData.property) {
      setError('Please select a property.');
      setIsSubmitting(false);  // Re-enable the form if there is an error
      return;
  }

  try {
      // Prepare all the data to be passed to AMEmailSender
      const jobDataForEmail = {
          supervisorName: formData.supervisorName,
          property: formData.property,
          customPropertyName: formData.customPropertyName,
          serviceTypes: formData.serviceTypes,
          customServiceDescription: formData.customServiceDescription,
          notes: formData.notes,
          date: formData.date,
          image: formData.image,
          secondImage: formData.secondImage,
          thirdImage: formData.thirdImage,
          fourthImage: formData.fourthImage,
          fifthImage: formData.fifthImage,
          truckNumber: formData.truckNumber,
          truckDriver: formData.truckDriver,
          isEditing,
          jobId,
          createdBy: userEmail,
          userType: formData.userType
      };

      // Set job data for email
      setSubmittedJobData(jobDataForEmail);

      // Trigger email sending modal
      setEmailSenderOpen(true);
      
      setIsSubmitting(false); // Re-enable the form after submission

      localStorage.removeItem('savedJobForm');

  } catch (error) {
      console.error('Error preparing data:', error);
      setError('An error occurred while preparing the form data. Please try again.');
      setIsSubmitting(false); // Re-enable the form in case of an error
  }
};


  return (
    <>
      <Form onSubmit={handleSubmit}>
  <Input
    type="text"
    name="supervisorName"
    value={formData.supervisorName}
    onChange={handleChange}
    placeholder={t('Supervisor Name')}
    required
    disabled={isSubmitting}  // Disable when submitting
  />
  <Input
    type="text"
    name="truckNumber"
    value={formData.truckNumber}
    onChange={handleChange}
    placeholder={t('Truck Number')}
    disabled={isSubmitting}  // Disable when submitting
  />
  <Input
    type="text"
    name="truckDriver"
    value={formData.truckDriver}
    onChange={handleChange}
    placeholder={t('Truck Driver')}
    disabled={isSubmitting}  // Disable when submitting
  />
  <Input
    type="date"
    name="date"
    value={formData.date}
    onChange={handleChange}
    required
    disabled={isSubmitting}  // Disable when submitting
  />
  <PropertyList 
    onSelect={(property) => 
      setFormData(prev => ({ ...prev, property: property["Property Name"] }))
    }
    required
    disabled={isSubmitting}  // Disable when submitting
  />
  <Input
    type="text"
    name="customPropertyName"
    value={formData.customPropertyName}
    onChange={handleChange}
    placeholder={t('Custom Property Name')}
    disabled={isSubmitting}  // Disable when submitting
  />
  {services.map(service => (
    <ServiceCheckbox key={service}>
      <input
        type="checkbox"
        id={service}
        name="serviceTypes"
        value={service}
        checked={formData.serviceTypes.includes(service)}
        onChange={handleServiceChange}
        disabled={isSubmitting}  // Disable when submitting
      />
      <CheckboxLabel htmlFor={service}>{t(service)}</CheckboxLabel>
    </ServiceCheckbox>
  ))}
  {formData.serviceTypes.includes('Other') && (
    <Input
      type="text"
      name="customServiceDescription"
      value={formData.customServiceDescription}
      onChange={handleChange}
      placeholder={t('If Services Not Listed, Add Them Here')}
      required
      disabled={isSubmitting}  // Disable when submitting
    />
  )}
  <Input
    type="text"
    name="notes"
    value={formData.notes}
    onChange={handleChange}
    placeholder={t('Additional Notes')}
    disabled={isSubmitting}  // Disable when submitting
  />
  <h3>You Can Upload up to 5 Images</h3>
<StyledImageUpload>
  {['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].map((imageName) => (
    <div key={imageName}>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {formData[imageName] && (
        <div className="image-preview">
          <img src={formData[imageName] instanceof File ? URL.createObjectURL(formData[imageName]) : formData[imageName]} alt={`Preview ${imageName}`} />
          <RemoveButton onClick={() => handleRemoveImage(imageName)} disabled={isSubmitting}>
            Remove
          </RemoveButton>
        </div>
      )}
    </div>
  ))}
</StyledImageUpload>;
  <StyledButton type="submit" disabled={isSubmitting}>
  {isEditing ? t('Update Job') : t('Submit Job')}
</StyledButton>
<StyledButton type="button" onClick={handleSaveAndClose}>
  Save and Close
</StyledButton>
  {error && <ErrorMessage>{error}</ErrorMessage>}
  {successMessage && <div style={{ color: 'green', marginTop: '10px' }}>{successMessage}</div>}
</Form>


      {isEmailSenderOpen && (
        <>
          <ModalOverlay onClick={() => setEmailSenderOpen(false)} />
          <ModalContent>
          <AMEmailSender
            jobId={jobId}
            job={submittedJobData}
            onClose={() => {
        setEmailSenderOpen(false);
        navigate('/supervisor-dashboard');
    }}
/>
          </ModalContent>
        </>
      )}
    </>
  );
};

export default JobForm;