import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth, db } from '../Firebase Functions/firebase';
import styled from 'styled-components';
import { doc, setDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { serverTimestamp } from 'firebase/firestore';

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  max-width: 400px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -50px;
  transform: translateY(-50px);
`;

const SignUpForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #ecf0f1;
`;

const SignUpInput = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 1rem;
  transition: border 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #27ae60;
    box-shadow: 0 0 4px rgba(39, 174, 96, 0.5);
    outline: none;
  }
`;

const SignUpButton = styled.button`
  width: 100%;
  background-color: #27ae60;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #219150;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const StyledLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: #3498db;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.875rem;
  margin-top: 1rem;

  &:hover {
    color: #2c80ba;
  }
`;

const FormHeading = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
`;

const SignUp = ({ onLoginClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const roleCode = searchParams.get('roleCode');
    if (roleCode === 'ABC123') {
      setRole('supervisor');
    } else if (roleCode === 'XYZ789') {
      setRole('manager');
    } else if (roleCode === 'DEF456') {
      setRole('spray_tech');
    } else if (roleCode === 'GHI789') {
      setRole('spray_manager');
    } else if (roleCode === 'JKL123') {
      setRole('admin');  
    } else {
      setError('Invalid or missing role code in the signup link.');
    }
  }, [searchParams]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
  
    if (loading) return; // Prevent multiple submissions
    setLoading(true);
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }
  
    if (!role) {
      setError('A valid role is required to sign up.');
      setLoading(false);
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      await setDoc(doc(db, 'Users', user.uid), {
        email,
        first_name: firstName,
        last_name: lastName,
        type: role,
        created_at: serverTimestamp(),
      });
  
      await sendEmailVerification(user);
      alert(`A verification email has been sent. Please verify your email to complete registration as a ${role}.`);
  
      setError('');
      navigate('/login');
    } catch (error) {
      console.error('Signup error:', error);
      setError('Failed to create an account. ' + error.message);
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  


  return (
    <SignUpForm onSubmit={handleSignUp}>
      <SignUpContainer>
        <FormHeading>{t('Create an Account')}</FormHeading>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <SignUpInput
          type="email"
          placeholder={t('Email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <SignUpInput
          type="text"
          placeholder={t('First Name')}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <SignUpInput
          type="text"
          placeholder={t('Last Name')}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <SignUpInput
          type="password"
          placeholder={t('Password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <SignUpInput
          type="password"
          placeholder={t('Confirm Password')}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <SignUpButton type="submit">{t('Sign up')}</SignUpButton>
        <StyledLinkButton type="button" onClick={onLoginClick}>
          {t('Already have an account? Log in')}
        </StyledLinkButton>
      </SignUpContainer>
    </SignUpForm>
  );
};

export default SignUp;