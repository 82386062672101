import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';

const quickbaseApi = axios.create({
    baseURL: 'https://api.quickbase.com/v1',
    headers: {
        'QB-Realm-Hostname': process.env.REACT_APP_QB_REALM_HOSTNAME,
        'Authorization': `QB-USER-TOKEN ${process.env.REACT_APP_QB_API_KEY}`,
        'Content-Type': 'application/json'
    }
});

const UpdateQuickBaseVehicleInspection = async (inspectionId, signatureBase64) => {
    try {
        // Step 1: Fetch inspection data from Firebase
        const docRef = doc(db, 'vehicleInspections', inspectionId);
        const docSnapshot = await getDoc(docRef);

        if (!docSnapshot.exists()) {
            throw new Error('Vehicle inspection document not found.');
        }

        const data = docSnapshot.data();

        const {
            truckId,
            trailerId,
            date,
            time,
            inspectionType,
            odometer,
            checklist,
            trailerChecklist,
            comments,
            signature
        } = data;

        // Convert checklist and trailerChecklist object arrays to list of keys with true values
        const extractTrueKeys = obj => {
            if (!obj || typeof obj !== 'object') return '';
            return Object.entries(obj)
                .filter(([_, value]) => value === true)
                .map(([key]) => key)
                .join(' ; ');
        };

        const checklistTrue = extractTrueKeys(checklist);
        const trailerChecklistTrue = extractTrueKeys(trailerChecklist);

        // Step 2: Build the Quickbase payload
        const quickbasePayload = {
            to: 'buz6msaak', // New table ID
            data: [
                {
                    7: { value: truckId || '' },
                    8: { value: trailerId || '' },
                    9: { value: date || '' },
                    10: { value: time || '' },
                    11: { value: inspectionId }, // doc ID passed to field #11
                    12: { value: inspectionType || '' },
                    13: { value: odometer || '' },
                    14: { value: checklistTrue },
                    15: { value: trailerChecklistTrue },
                    16: { value: comments || '' },
                    17: {
                        value: {
                            fileName: `${inspectionId}_signature.png`,
                            data: signatureBase64 || signature || ''
                        }
                    }
                }
            ]
        };

        // Step 3: Send the payload to Quickbase
        const response = await quickbaseApi.post('/records', quickbasePayload);
        console.log('✅ Quickbase update successful:', response.data);

        if (response.data?.metadata?.createdRecordIds) {
            return {
                data: response.data,
                createdRecordId: response.data.metadata.createdRecordIds[0]
            };
        } else {
            throw new Error('Unexpected Quickbase response format.');
        }
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
        console.error('❌ Error updating Quickbase:', errorMessage);
        throw new Error(errorMessage);
    }
};

export default UpdateQuickBaseVehicleInspection;
