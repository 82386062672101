import React, { useState, useEffect, useContext } from 'react';
import { doc, deleteDoc, updateDoc, getDocs, collection, query, where } from 'firebase/firestore';
import styled from 'styled-components';
import { db } from './Firebase Functions/firebase';
import { AuthContext } from './hooks/AuthProvider';
import CompactTaskViewer from './CompactTaskViewer';
import AddTaskModal from './AddTaskModal';

const TaskListContainer = styled.div`
  margin: 80px auto 2rem;
  width: 100%;
  max-width: 800px;
  padding: 0 1rem;
  min-height: calc(100vh - 160px);
`;

const DeleteButton = styled.button`
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d9363e;
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
`;


const TaskItem = styled.div`
  background: ${({ allCompleted }) => (allCompleted ? '#d4edda' : '#fff3cd')}; 
  border: ${({ allCompleted }) => (allCompleted ? '1px solid #c3e6cb' : '1px solid #ffeeba')};
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;


const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;

  &:checked {
    background-color: #27ae60;
    border-color: #27ae60;
  }

  &:checked::before {
    content: '✔';
    color: white;
    font-size: 14px;
    display: block;
    text-align: center;
    line-height: 20px;
  }
`;

const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;  /* ✅ Ensures children (like CloseButton) are positioned inside */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* ✅ Ensures content starts from the top */
`;


const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;  /* ✅ Adjusted to be inside */
  right: 10px; /* ✅ Moved closer inside */
  padding: 0.5rem;
  color: #666;
  z-index: 10; /* ✅ Ensures it appears above content */

  &:hover {
    color: #000;
  }

  @media (max-width: 600px) {  
    font-size: 1.2rem;
    padding: 0.4rem;
  }
`;



const ExpandableContent = styled.div`
  max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const AddTaskButton = styled.button`
  background: #27ae60;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    background: #219653;
  }
`;

const SectionTitle = styled.h2`
  margin-top: 1.5rem;
  color: #2c3e50;
`;

const TaskList = () => {
  const { currentUser: user } = useContext(AuthContext);
  const [tasks, setTasks] = useState([]); // Punch lists created by the user (manager)
  const [assignedTasks, setAssignedTasks] = useState([]); // Punch lists assigned to the user
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const q = query(collection(db, 'tasks'), where('managerId', '==', user.uid));
        const snapshot = await getDocs(q);
        setTasks(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching created tasks:', error);
      }
    };

    fetchTasks();
  }, [user]);

  useEffect(() => {
    const fetchAssignedTasks = async () => {
      try {
        const q = query(collection(db, 'tasks'), where('assignedUserId', '==', user.uid));
        const snapshot = await getDocs(q);
        setAssignedTasks(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Error fetching assigned tasks:', error);
      }
    };

    fetchAssignedTasks();
  }, [user]);

  

  const handleTaskAdded = () => {
    setIsAddTaskModalOpen(false);
  
    const fetchTasks = async () => {
      try {
        const q = query(collection(db, 'tasks'), where('managerId', '==', user.uid));
        const snapshot = await getDocs(q);
  
        const currentDate = new Date();
        const fetchedTasks = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((task) => {
            const dueDate = new Date(task.completionWeek);
            return !isNaN(dueDate) && (dueDate >= currentDate || (currentDate - dueDate) / (1000 * 60 * 60 * 24) <= 30);
          })
          .sort((a, b) => {
            const dueDateA = new Date(a.completionWeek);
            const dueDateB = new Date(b.completionWeek);
            return dueDateA - dueDateB;
          });
  
        setTasks(fetchedTasks);
      } catch (error) {
        console.error('Error refreshing tasks:', error);
      }
    };
  
    fetchTasks();
  };

  const handleTaskDeleted = async (taskId) => {
    // Step 1: Optimistically update tasks and close the modal
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
    closeModal(); // Immediately close the modal
  
    try {
      // Step 2: Perform delete operation in Firestore
      const taskRef = doc(db, 'tasks', taskId);
      await deleteDoc(taskRef);
      console.log(`Task with ID ${taskId} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting task:', error);
  
      // Step 3: Rollback in case of failure
      setTasks((prevTasks) => {
        // Reinsert the deleted task (rollback)
        const deletedTask = selectedTask[0];
        return [...prevTasks, deletedTask].sort((a, b) => {
          const dueDateA = new Date(a.completionWeek);
          const dueDateB = new Date(b.completionWeek);
          return dueDateA - dueDateB;
        });
      });
      alert('Failed to delete the task. Please try again.');
    }
  };

  

  const handleTaskCompletion = async (taskId, index, isCompleted) => {
    setAssignedTasks(prev =>
      prev.map(task =>
        task.id === taskId
          ? {
              ...task,
              tasks: task.tasks.map((subtask, i) =>
                i === index ? { ...subtask, completed: isCompleted } : subtask
              ),
            }
          : task
      )
    );
  
    setTasks(prev =>
      prev.map(task =>
        task.id === taskId
          ? {
              ...task,
              tasks: task.tasks.map((subtask, i) =>
                i === index ? { ...subtask, completed: isCompleted } : subtask
              ),
            }
          : task
      )
    );
  
    try {
      const taskRef = doc(db, 'tasks', taskId);
      const updatedTask = assignedTasks.find(task => task.id === taskId);
      if (updatedTask) {
        const updatedTasks = updatedTask.tasks.map((subtask, i) =>
          i === index ? { ...subtask, completed: isCompleted } : subtask
        );
        await updateDoc(taskRef, { tasks: updatedTasks });
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };
  

  const openModal = (taskId) => {
    setSelectedTaskId(taskId);
    setIsTaskModalOpen(true);
  };

  const toggleTaskExpansion = (taskId) => {
  setExpandedTaskId((prev) => (prev === taskId ? null : taskId));
};

  const closeModal = () => {
    setSelectedTaskId(null);
    setIsTaskModalOpen(false);
  };

  const selectedTaskData = assignedTasks.find(task => task.id === selectedTaskId);

  return (
    <TaskListContainer>
      {/* Create New Punch List Button */}
      <AddTaskButton onClick={() => setIsAddTaskModalOpen(true)}>
        Create New Punch List
      </AddTaskButton>
     {/* Section: Tasks Assigned to User */}
<SectionTitle>Your Assigned PunchLists</SectionTitle>
{assignedTasks.length > 0 ? (
  assignedTasks.map((task) => {
    const totalTasks = task.tasks?.length || 0;
    const completedTasks = task.tasks?.filter(subtask => subtask.completed).length || 0;
    const allCompleted = totalTasks > 0 && completedTasks === totalTasks;

    return (
      <TaskItem key={task.id} allCompleted={allCompleted} onClick={() => openModal(task.id)}>
        <p><strong>Property:</strong> {task.propertyName}</p>
        <p><strong>Completion Week:</strong> {task.completionWeek}</p>
        <p><strong>Assigned By:</strong> {task.assignedBy?.name || "Unknown"}</p>

        {/* Completion Ratio */}
        <p style={{ fontWeight: 'bold', color: allCompleted ? '#155724' : '#856404' }}>
          {completedTasks}/{totalTasks} tasks completed
        </p>

        {/* Show "Completed" label when all tasks are done */}
        {allCompleted && <p style={{ fontWeight: 'bold', color: '#155724' }}>✅ Completed</p>}
      </TaskItem>
    );
  })
) : (
  <p>No punch lists assigned to you.</p>
)}



     {/* Modal for Assigned Task Details */}
     {/* Modal for Assigned Task Details */}
{/* Modal for Assigned Task Details */}
<ModalOverlay isOpen={isTaskModalOpen}>
  <ModalContent>
    <CloseButton onClick={closeModal}>✖</CloseButton>
    {selectedTaskData ? (
      <>
        <h3>{selectedTaskData.propertyName || "No Property Name"}</h3>
        <p><strong>Assigned By:</strong> {selectedTaskData.assignedBy?.name || "Unknown"}</p>
        <p><strong>Due Date:</strong> {selectedTaskData.completionWeek || "No Due Date"}</p>

        {/* Ensure tasks are displayed */}
        {selectedTaskData?.tasks?.length > 0 ? (
          selectedTaskData.tasks.map((taskItem, index) => (
            <div key={index} style={{ marginBottom: "0.5rem" }}>
              <label>
                <Checkbox
                  type="checkbox"
                  checked={taskItem.completed || false}
                  onChange={(e) =>
                    handleTaskCompletion(selectedTaskData.id, index, e.target.checked)
                  }
                />
                {taskItem.note || "No note provided"}
              </label>

              {/* ✅ Click to Enlarge Image */}
              {taskItem.type === 'image' && taskItem.url && (
                <img
                  src={taskItem.url}
                  alt={`Task Image ${index + 1}`}
                  style={{ width: '100px', borderRadius: '5px', marginTop: '5px', cursor: 'pointer' }}
                  onClick={() => setSelectedImage(taskItem.url)}
                  onError={(e) => e.target.style.display = 'none'} // Hide broken images
                />
              )}
            </div>
          ))
        ) : (
          <p>No tasks assigned.</p>
        )}
      </>
    ) : (
      <p>Loading task details...</p>
    )}
  </ModalContent>
</ModalOverlay>


{/* Image Modal for Enlarged View */}
{selectedImage && (
  <ModalOverlay isOpen={!!selectedImage} onClick={() => setSelectedImage(null)}>
    <ModalContent style={{ maxWidth: "90vw", maxHeight: "90vh", padding: 0 }}>
      <CloseButton onClick={() => setSelectedImage(null)}>✖</CloseButton>
      <img
        src={selectedImage}
        alt="Enlarged Task"
        style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: "8px" }}
      />
    </ModalContent>
  </ModalOverlay>
)}


  
     {/* Section: Tasks Created by User */}
{/* Section: Tasks Created by User */}
<SectionTitle>Your Created Punch Lists</SectionTitle>
{tasks.length > 0 ? (
  tasks.map((task) => {
    const totalTasks = task.tasks?.length || 0;
    const completedTasks = task.tasks?.filter(subtask => subtask.completed).length || 0;
    const allCompleted = totalTasks > 0 && completedTasks === totalTasks;

    return (
      <TaskItem key={task.id} allCompleted={allCompleted}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div onClick={() => toggleTaskExpansion(task.id)} style={{ cursor: "pointer", flexGrow: 1 }}>
            <p><strong>Property:</strong> {task.propertyName}</p>
            <p><strong>Completion Week:</strong> {task.completionWeek}</p>
            <p><strong>Assigned To:</strong> {task.assignedUserName}</p>
          </div>
          <DeleteButton onClick={() => handleTaskDeleted(task.id)}>🗑 Delete</DeleteButton>
        </div>

        {/* Completion Ratio */}
        <p style={{ fontWeight: 'bold', color: allCompleted ? '#155724' : '#856404' }}>
          {completedTasks}/{totalTasks} tasks completed
        </p>

        {/* Show "Completed" label when all tasks are done */}
        {allCompleted && <p style={{ fontWeight: 'bold', color: '#155724' }}>✅ Completed</p>}

        <ExpandableContent isOpen={expandedTaskId === task.id}>
          {task.tasks && task.tasks.length > 0 ? (
            task.tasks.map((taskItem, index) => (
              <div key={index}>
                <p><strong>Task {index + 1}:</strong> {taskItem.note}</p>

                {/* ✅ Click to Enlarge Image */}
                {taskItem.type === 'image' && taskItem.url && (
                  <img
                    src={taskItem.url}
                    alt={`Task Image ${index + 1}`}
                    style={{ width: '100px', borderRadius: '5px', marginTop: '5px', cursor: 'pointer' }}
                    onClick={() => setSelectedImage(taskItem.url)}
                    onError={(e) => e.target.style.display = 'none'} // Hide broken images
                  />
                )}
              </div>
            ))
          ) : (
            <p>No tasks added yet.</p>
          )}
        </ExpandableContent>
      </TaskItem>
    );
  })
) : (
  <p>You haven't created any punch lists yet.</p>
)}



      <AddTaskModal 
   isOpen={isAddTaskModalOpen}
   onClose={() => setIsAddTaskModalOpen(false)}
   user={user}
   onTaskAdded={handleTaskAdded} 
/>

    </TaskListContainer>
    
  );
  
};

export default TaskList;
