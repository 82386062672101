import React, { useState, useRef, useContext } from "react";
import SignatureCanvas from "react-signature-canvas";
import { db } from '../Firebase Functions/firebase';
import { addDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../hooks/AuthProvider";
import "../TruckServices/ChecklistForm.css";
import IncidentEmailSender from "../Email Functions/IncidentEmailSender";

function IncidentReportForm() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [imageFiles, setImageFiles] = useState([]);
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ EmailModalOpen, setEmailModalOpen] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);
  const supervisorSigRef = useRef(null);
  const [selectedReport, setSelectedReport] = useState(null);

  const driverSigRef = useRef(null);
  const passengerSigRef = useRef(null);

  const [formData, setFormData] = useState({
    employee: {
      name: "",
      address: "",
      phone: "",
      division: "",
      jobTitle: ""
    },
    occurrence: {
      startTime: "",
      accidentTime: "",
      weather: "",
      injuryDate: "",
      notificationDate: "",
      location: ""
    },
    injury: {
      type: "",
      bodyPart: "",
      equipmentUsed: "",
      activity: "",
      assignedJob: false,
      ppeRequired: false,
      receivedTraining: false,
      worePPE: false
    },
    witnesses: [],
    treatment: {
      initialTreatment: false,
      facility: "",
      accompanied: false,
      byWhom: ""
    },
    vehicle: {
      truckNumber: "",
      damageDescription: "",
      claimant: {
        name: "",
        phone: ""
      }
    },
    correctiveAction: {
      explanation: "",
      employeeNegligence: false,
      deduction: false
    },
    supervisor: {
      name: "",
      phone: ""
    },
    driver: {
      name: "",
      phone: ""
    },
    passenger: {
      name: "",
      phone: ""
    },
    attachments: {
      releaseFormSigned: false
    }
  });

  const handleChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleNestedChange = (section, subSection, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [subSection]: {
          ...prev[section][subSection],
          [field]: value
        }
      }
    }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + imageFiles.length > 10) {
      alert("You can only upload up to 10 images.");
      return;
    }
    setImageFiles(prev => [...prev, ...files]);
  };

  const handleRemoveImage = (indexToRemove) => {
    setImageFiles((prev) => prev.filter((_, index) => index !== indexToRemove));
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setEmailModalOpen(true);

    setStatus('Uploading images...');
  
    try {
      const uploadedImageURLs = [];
  
      const storage = getStorage();
      for (const file of imageFiles) {
        const storageRef = ref(storage, `incidentReports/${Date.now()}-${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        uploadedImageURLs.push(downloadURL);
      }
  
      console.log('[IncidentReportForm] Images uploaded:', uploadedImageURLs.length);
      setStatus('Saving report to Firestore...');
  
      const reportData = {
        ...formData,
        supervisor: {
          ...formData.supervisor,
          signature: supervisorSigRef.current?.getTrimmedCanvas().toDataURL("image/png") || null
        },
        driver: {
          ...formData.driver,
          signature: driverSigRef.current?.getTrimmedCanvas().toDataURL("image/png") || null
        },
        passenger: {
          ...formData.passenger,
          signature: passengerSigRef.current?.getTrimmedCanvas().toDataURL("image/png") || null
        },
        attachments: {
          ...formData.attachments,
          imageURLs: uploadedImageURLs
        },
        timestamp: new Date().toISOString(),
        createdBy: currentUser?.email || "anonymous"
      };

      setSelectedReport(reportData);
  
      await addDoc(collection(db, "incidentReports"), reportData);
  
      console.log('[IncidentReportForm] Report saved successfully');
      setStatus('Incident report submitted. Sending email...');
      setEmailModalOpen(true);
  
    } catch (error) {
      console.error('[IncidentReportForm] Error submitting report:', error);
      setStatus('Failed to submit report. Please try again.');
      alert("Failed to submit incident report.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  return (
    <form onSubmit={handleSubmit} className="checklist-form">
      <h1 className="form-title">Incident Investigation Report</h1>

     {/* Employee Information */}
<h2 className="section-title">Employee Information</h2>

<div className="form-group">
  <label>Full Name (Last, First, MI)</label>
  <input
    type="text"
    value={formData.employee.name}
    onChange={(e) => handleChange("employee", "name", e.target.value)}
    className="form-control"
    required
  />
</div>

<div className="form-group">
  <label>Address (Including Zip)</label>
  <input
    type="text"
    value={formData.employee.address}
    onChange={(e) => handleChange("employee", "address", e.target.value)}
    className="form-control"
    required
  />
</div>

<div className="form-group">
  <label>Phone Number</label>
  <input
    type="tel"
    value={formData.employee.phone}
    onChange={(e) => handleChange("employee", "phone", e.target.value)}
    className="form-control"
    required
  />
</div>

<div className="form-group">
  <label>Division #</label>
  <input
    type="text"
    value={formData.employee.division}
    onChange={(e) => handleChange("employee", "division", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Job Title</label>
  <input
    type="text"
    value={formData.employee.jobTitle}
    onChange={(e) => handleChange("employee", "jobTitle", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Involved Truck Number</label>
  <input
    type="text"
    value={formData.vehicle.truckNumber}
    onChange={(e) => handleChange("vehicle", "truckNumber", e.target.value)}
    className="form-control"
  />
</div>

{/* Release Form Signed */}
<div className="form-group">
  <label>Release Form Signed</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.attachments.releaseFormSigned === true}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            attachments: {
              ...prev.attachments,
              releaseFormSigned: true
            }
          }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.attachments.releaseFormSigned === false}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            attachments: {
              ...prev.attachments,
              releaseFormSigned: false
            }
          }))
        }
      />
      No
    </label>
  </div>
</div>

{/* Pictures Attached */}
<div className="form-group">
  <label>Pictures Attached</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.attachments.picturesAttached === true}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            attachments: {
              ...prev.attachments,
              picturesAttached: true
            }
          }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.attachments.picturesAttached === false}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            attachments: {
              ...prev.attachments,
              picturesAttached: false
            }
          }))
        }
      />
      No
    </label>
  </div>
</div>



{/* Occurrence Section */}
<h2 className="section-title">Occurrence Details</h2>

<div className="form-group">
  <label>Time Employee Began Work</label>
  <input
    type="time"
    value={formData.occurrence.startTime}
    onChange={(e) => handleChange("occurrence", "startTime", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Time of Accident</label>
  <input
    type="time"
    value={formData.occurrence.accidentTime}
    onChange={(e) => handleChange("occurrence", "accidentTime", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Weather</label>
  <input
    type="text"
    value={formData.occurrence.weather}
    onChange={(e) => handleChange("occurrence", "weather", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Date of Injury</label>
  <input
    type="date"
    value={formData.occurrence.injuryDate}
    onChange={(e) => handleChange("occurrence", "injuryDate", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Date Notified</label>
  <input
    type="date"
    value={formData.occurrence.notificationDate}
    onChange={(e) => handleChange("occurrence", "notificationDate", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Location of Incident</label>
  <input
    type="text"
    value={formData.occurrence.location}
    onChange={(e) => handleChange("occurrence", "location", e.target.value)}
    className="form-control"
  />
</div>

{/* Injury Info */}
<h2 className="section-title">Injury / Illness Details</h2>

<div className="form-group">
  <label>Type of Injury or Illness</label>
  <input
    type="text"
    value={formData.injury.type}
    onChange={(e) => handleChange("injury", "type", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Body Part Affected</label>
  <input
    type="text"
    value={formData.injury.bodyPart}
    onChange={(e) => handleChange("injury", "bodyPart", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Equipment / Materials Used</label>
  <input
    type="text"
    value={formData.injury.equipmentUsed}
    onChange={(e) => handleChange("injury", "equipmentUsed", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Specific Activity at Time of Injury</label>
  <input
    type="text"
    value={formData.injury.activity}
    onChange={(e) => handleChange("injury", "activity", e.target.value)}
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Was this caused by employee negligence?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.correctiveAction.employeeNegligence === true}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            correctiveAction: {
              ...prev.correctiveAction,
              employeeNegligence: true
            }
          }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.correctiveAction.employeeNegligence === false}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            correctiveAction: {
              ...prev.correctiveAction,
              employeeNegligence: false
            }
          }))
        }
      />
      No
    </label>
  </div>
</div>

{/* Will there be an employee deduction? */}
<div className="form-group">
  <label>Will there be an employee deduction?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.correctiveAction.deduction === true}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            correctiveAction: {
              ...prev.correctiveAction,
              deduction: true
            }
          }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.correctiveAction.deduction === false}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            correctiveAction: {
              ...prev.correctiveAction,
              deduction: false
            }
          }))
        }
      />
      No
    </label>
  </div>
</div>

{/* If no deduction, explain why */}
{formData.correctiveAction.deduction === false && (
  <div className="form-group">
    <label>If no employee deduction, please explain why:</label>
    <textarea
      value={formData.correctiveAction.explanation}
      onChange={(e) =>
        setFormData((prev) => ({
          ...prev,
          correctiveAction: {
            ...prev.correctiveAction,
            explanation: e.target.value
          }
        }))
      }
      className="form-control"
    />
  </div>
)}

{/* Were there any witnesses? */}
<div className="form-group">
  <label>Were there any witnesses?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.witnesses.length > 0}
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            witnesses: e.target.checked ? [""] : []
          }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.witnesses.length === 0}
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            witnesses: []
          }))
        }
      />
      No
    </label>
  </div>
</div>

{/* Witness names (if any) */}
{formData.witnesses.length > 0 && (
  <div className="form-group">
    <label>List Witness Names</label>
    {formData.witnesses.map((witness, index) => (
      <input
        key={index}
        type="text"
        value={witness}
        onChange={(e) => {
          const updated = [...formData.witnesses];
          updated[index] = e.target.value;
          setFormData((prev) => ({ ...prev, witnesses: updated }));
        }}
        className="form-control"
        placeholder={`Witness ${index + 1}`}
      />
    ))}
    <button
      type="button"
      onClick={() =>
        setFormData((prev) => ({
          ...prev,
          witnesses: [...prev.witnesses, ""]
        }))
      }
      className="add-button"
    >
      + Add Witness
    </button>
  </div>
)}

{/* Was there a passenger? */}
<div className="form-group">
  <label>Was there a passenger involved?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.passengerInvolved === true}
        onChange={() =>
          setFormData((prev) => ({ ...prev, passengerInvolved: true }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.passengerInvolved === false}
        onChange={() =>
          setFormData((prev) => ({ ...prev, passengerInvolved: false }))
        }
      />
      No
    </label>
  </div>
</div>
{formData.passengerInvolved && (
  <div className="form-group">
    <h3>Passenger</h3>

    <label>Name</label>
    <input
      type="text"
      value={formData.passenger.name}
      onChange={(e) => handleChange("passenger", "name", e.target.value)}
      className="form-control"
    />

    <label>Phone</label>
    <input
      type="tel"
      value={formData.passenger.phone}
      onChange={(e) => handleChange("passenger", "phone", e.target.value)}
      className="form-control"
    />

    <label>Date</label>
    <input
      type="date"
      value={formData.passenger.date || ""}
      onChange={(e) => handleChange("passenger", "date", e.target.value)}
      className="form-control"
    />

    <label>Signature</label>
    <SignatureCanvas
      ref={passengerSigRef}
      penColor="black"
      canvasProps={{
        className: "signature-canvas",
        style: { border: "1px dashed #ccc", width: "100%", height: "150px" }
      }}
    />
    <p className="signature-hint">Sign within the box</p>
    <button type="button" onClick={() => passengerSigRef.current.clear()} className="clear-button">
      Clear Signature
    </button>
  </div>
)}


{/* Damaged Vehicle Description */}
<div className="form-group">
  <label>Damaged Vehicle Description (Year, Make, Model)</label>
  <input
    type="text"
    value={formData.vehicle.damageDescription || ""}
    onChange={(e) =>
      setFormData((prev) => ({
        ...prev,
        vehicle: {
          ...prev.vehicle,
          damageDescription: e.target.value
        }
      }))
    }
    className="form-control"
  />
</div>

{/* Claimant info */}
<div className="form-group">
  <label>Claimant's Name</label>
  <input
    type="text"
    value={formData.vehicle.claimant.name}
    onChange={(e) =>
      handleNestedChange("vehicle", "claimant", "name", e.target.value)
    }
    className="form-control"
  />
</div>

<div className="form-group">
  <label>Claimant's Phone</label>
  <input
    type="tel"
    value={formData.vehicle.claimant.phone}
    onChange={(e) =>
      handleNestedChange("vehicle", "claimant", "phone", e.target.value)
    }
    className="form-control"
  />
</div>

{/* Medical Section */}
<h2 className="section-title">Medical Treatment</h2>

{/* Accompanied to Hospital/Clinic */}
<div className="form-group">
  <label>Was employee accompanied to the hospital/clinic?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.treatment.accompanied === true}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            treatment: { ...prev.treatment, accompanied: true }
          }))
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.treatment.accompanied === false}
        onChange={() =>
          setFormData((prev) => ({
            ...prev,
            treatment: { ...prev.treatment, accompanied: false }
          }))
        }
      />
      No
    </label>
  </div>
</div>

{formData.treatment.accompanied && (
  <div className="form-group">
    <label>By Whom</label>
    <input
      type="text"
      value={formData.treatment.byWhom}
      onChange={(e) =>
        handleChange("treatment", "byWhom", e.target.value)
      }
      className="form-control"
    />
  </div>
)}

{/* Initial Treatment */}
<div className="form-group">
  <label>Initial Treatment?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.treatment.initialTreatment === true}
        onChange={() =>
          handleChange("treatment", "initialTreatment", true)
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.treatment.initialTreatment === false}
        onChange={() =>
          handleChange("treatment", "initialTreatment", false)
        }
      />
      No
    </label>
  </div>
</div>

{/* Denied Medical Treatment */}
<div className="form-group">
  <label>Denied Medical Treatment?</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.treatment.denied === true}
        onChange={() =>
          handleChange("treatment", "denied", true)
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.treatment.denied === false}
        onChange={() =>
          handleChange("treatment", "denied", false)
        }
      />
      No
    </label>
  </div>
</div>

{/* Minor Clinic */}
<div className="form-group">
  <label>Minor Clinic</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.treatment.minorClinic === true}
        onChange={() =>
          handleChange("treatment", "minorClinic", true)
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.treatment.minorClinic === false}
        onChange={() =>
          handleChange("treatment", "minorClinic", false)
        }
      />
      No
    </label>
  </div>
</div>

{/* Emergency */}
<div className="form-group">
  <label>Emergency</label>
  <div className="toggle-buttons">
    <label>
      <input
        type="checkbox"
        checked={formData.treatment.emergency === true}
        onChange={() =>
          handleChange("treatment", "emergency", true)
        }
      />
      Yes
    </label>
    <label style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        checked={formData.treatment.emergency === false}
        onChange={() =>
          handleChange("treatment", "emergency", false)
        }
      />
      No
    </label>
  </div>
</div>


<div className="form-group">
  <label>
    <input
      type="checkbox"
      checked={formData.injury.assignedJob}
      onChange={(e) => handleChange("injury", "assignedJob", e.target.checked)}
    />
    Was employee doing assigned job?
  </label>
</div>

<div className="form-group">
  <label>
    <input
      type="checkbox"
      checked={formData.injury.ppeRequired}
      onChange={(e) => handleChange("injury", "ppeRequired", e.target.checked)}
    />
    Was PPE required?
  </label>
</div>

<div className="form-group">
  <label>
    <input
      type="checkbox"
      checked={formData.injury.receivedTraining}
      onChange={(e) => handleChange("injury", "receivedTraining", e.target.checked)}
    />
    Did employee receive job-specific safety training?
  </label>
</div>

<div className="form-group">
  <label>
    <input
      type="checkbox"
      checked={formData.injury.worePPE}
      onChange={(e) => handleChange("injury", "worePPE", e.target.checked)}
    />
    Was employee wearing proper PPE?
  </label>
</div>

{/* Supervisor's Report */}
<h2 className="section-title">Supervisor's Report</h2>

<div className="form-group">
  <label>What happened? (Supervisor's Report)</label>
  <textarea
    value={formData.supervisor.report || ""}
    onChange={(e) =>
      setFormData((prev) => ({
        ...prev,
        supervisor: {
          ...prev.supervisor,
          report: e.target.value
        }
      }))
    }
    className="form-control"
    placeholder="Describe what occurred during the incident..."
    rows={4}
  />
</div>

{/* Corrective Action */}
<div className="form-group">
  <label>Corrective Action Taken</label>
  <textarea
    value={formData.correctiveAction.explanation}
    onChange={(e) =>
      setFormData((prev) => ({
        ...prev,
        correctiveAction: {
          ...prev.correctiveAction,
          explanation: e.target.value
        }
      }))
    }
    className="form-control"
    placeholder="Describe the steps taken to correct or address the issue..."
    rows={4}
  />
</div>

<h2 className="section-title">Attach Photos</h2>
<div className="form-group">
  <label>Upload up to 10 images</label>
  <input
    type="file"
    accept="image/*"
    multiple
    onChange={handleImageUpload}
    className="form-control"
  />
</div>

{imageFiles.length > 0 && (
  <div className="image-preview-grid">
    {imageFiles.map((file, index) => (
      <div key={index} style={{ margin: "10px", textAlign: "center" }}>
        <img
          src={URL.createObjectURL(file)}
          alt={`Preview ${index + 1}`}
          style={{ width: "150px", border: "1px solid #ccc", borderRadius: "4px" }}
        />
        <div style={{ fontSize: "0.8rem", marginTop: "4px" }}>{file.name}</div>
        <button
          type="button"
          onClick={() => handleRemoveImage(index)}
          className="clear-button"
          style={{ marginTop: "5px" }}
        >
          Remove
        </button>
      </div>
    ))}
  </div>
)}



<h2 className="section-title">Signatures</h2>

{/* Supervisor */}
<div className="form-group">
  <h3>Supervisor</h3>

  <label>Name</label>
  <input
    type="text"
    value={formData.supervisor.name}
    onChange={(e) => handleChange("supervisor", "name", e.target.value)}
    className="form-control"
  />

  <label>Phone</label>
  <input
    type="tel"
    value={formData.supervisor.phone}
    onChange={(e) => handleChange("supervisor", "phone", e.target.value)}
    className="form-control"
  />

  <label>Date</label>
  <input
    type="date"
    value={formData.supervisor.date || ""}
    onChange={(e) => handleChange("supervisor", "date", e.target.value)}
    className="form-control"
  />

  <label>Signature</label>
  <SignatureCanvas
    ref={supervisorSigRef}
    penColor="black"
    canvasProps={{ className: "signature-canvas", style: { border: "1px dashed #ccc", width: "100%", height: "150px" } }}
  />
  <p className="signature-hint">Sign within the box</p>
  <button type="button" onClick={() => supervisorSigRef.current.clear()} className="clear-button">
    Clear Signature
  </button>
</div>

{/* Driver */}
<div className="form-group">
  <h3>Driver</h3>

  <label>Name</label>
  <input
    type="text"
    value={formData.driver.name}
    onChange={(e) => handleChange("driver", "name", e.target.value)}
    className="form-control"
  />

  <label>Phone</label>
  <input
    type="tel"
    value={formData.driver.phone}
    onChange={(e) => handleChange("driver", "phone", e.target.value)}
    className="form-control"
  />

  <label>Date</label>
  <input
    type="date"
    value={formData.driver.date || ""}
    onChange={(e) => handleChange("driver", "date", e.target.value)}
    className="form-control"
  />

  <label>Signature</label>
  <SignatureCanvas
    ref={driverSigRef}
    penColor="black"
    canvasProps={{ className: "signature-canvas", style: { border: "1px dashed #ccc", width: "100%", height: "150px" } }}
  />
  <p className="signature-hint">Sign within the box</p>
  <button type="button" onClick={() => driverSigRef.current.clear()} className="clear-button">
    Clear Signature
  </button>
</div>




  <button type="submit">Send Incident Report</button>
    {status && (
      <div style={{ marginTop: '1rem', fontWeight: 'bold', color: isSubmitting ? '#555' : 'green' }}>
       {status}
      </div>
    )}

{EmailModalOpen && selectedReport && (
  <IncidentEmailSender
    selectedReport={selectedReport}
    onClose={() => {
      setEmailModalOpen(false);
      setSelectedReport(null);
      setStatus('Email process complete. You may now close or navigate away.');
    }}
  />
)}


    </form>

    
  );
}

export default IncidentReportForm;
