import axios from 'axios';

const QuickbaseUpdate = async ({ tableId, fields }) => {
  // Create an Axios instance with predefined configuration
  const quickbaseApi = axios.create({
    baseURL: 'https://api.quickbase.com/v1',
    headers: {
      'QB-Realm-Hostname': process.env.REACT_APP_QB_REALM_HOSTNAME,
      'Authorization': `QB-USER-TOKEN ${process.env.REACT_APP_QB_API_KEY}`,
      'Content-Type': 'application/json',
    },
  });
  
  
  try {
    const payload = {
      to: tableId,
      data: [
        {
          6: { value: fields.applicator },        // Applicator
          77: { value: fields.projectName },      // Project Name
          10: { value: fields.applicationDate },  // Application Date
          83: { value: fields.notes },            // Notes
          107: {
            value: {
              fileName: 'ChemicalApplicationReport.pdf',
              data: fields.pdfBase64,
            },
          },
        },
      ],
    };
  
    console.log('📦 Payload being sent to Quickbase:', JSON.stringify(payload, null, 2));
  
    const response = await quickbaseApi.post('/records', payload);
  
    console.log('✅ Quickbase Update Success:', response.data);
  
    // 🔍 Inspect and log any line errors
    const errors = response.data?.metadata?.lineErrors;
    if (errors && Object.keys(errors).length > 0) {
      console.warn('⚠️ Line errors detected in Quickbase response:');
      console.warn(JSON.stringify(errors, null, 2));
    }
  
    return response.data;
  } catch (error) {
    console.error('Quickbase Update Error:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message || error.message || 'An unknown error occurred');
  }
};  

export default QuickbaseUpdate;
