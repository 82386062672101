import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Define styles to match the layout
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  header: {
    textAlign: 'center',
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  boldText: {
    fontWeight: 'bold',
  },
  
  redText: {
    color: 'red',
  },
  
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  column: {
    flexDirection: 'column',
    flex: 1,
    fontSize: 10,
  },
  section: {
    marginBottom: 10,
  },
  checkboxContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 10,
  },
  checkboxItem: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '33%',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    padding: 2,
  },
  tableCell: {
    fontSize: 8,
    padding: 2,
    textAlign: 'center',
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: '#000',
  },
  tableCellLast: {
    borderRightWidth: 0,
  },
  notesSection: {
    fontSize: 10,
    marginTop: 10,
  },
});

const ChemicalApplicationPDF = ({ recordData }) => {
    if (!recordData) {
      return (
        <Document>
          <Page size="A4" style={styles.page}>
            <Text>Error: No record data available.</Text>
          </Page>
        </Document>
      );
    }
  
    const {
      applicator = 'N/A',
      applicatorId = 'N/A',
      ownerOperatorNumber = 'N/A',
      project = 'N/A',
      applicationDate = 'N/A',
      temperature = 'N/A',
      wind = 'N/A',
      areaTreated = 'N/A', // New field
      areaUnit = 'N/A',
      usedRestrictedPesticide = 'N/A',
      checkboxes = {},
      notes = 'No notes provided',
      address = 'N/A',
      city = 'N/A',
      state = 'N/A',
      chemicals = [],
    } = recordData;
  
    // Map of checkbox labels to keys in checkboxes
    const checkboxMap = {
      'Turf Fertilizer': 'turfFertilizer',
      'Turf Post-Emerge': 'turfPostEmerge',
      'Turf Pre-Emerge': 'turfPreEmerge',
      'Turf Insect': 'turfInsect',
      'Ant Control': 'antControl',
      'Bed Fertilizer': 'bedFertilizer',
      'Bed Post-Emerge': 'bedPostEmerge',
      'Bed Pre-Emerge': 'bedPreEmerge',
      'T/S Insect': 'tsInsect',
      'C/C Post-Emerge': 'ccPostEmerge',
      'T/S Disease': 'tsDisease',
      'T/S Fertilizer': 'tsFertilizer',
      'Palm Fertilizer': 'palmFertilizer',
      'Palm Fungicide': 'palmFungicide',
    };
  
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header Section */}
          <View style={styles.header}>
            <Text style={styles.title}>CHEMICAL APPLICATION RECORD</Text>
            <Text style={styles.subTitle}>
              Rotolo Consultants, Inc. • 38001 Brownsvillage Rd., Slidell, LA 70460
            </Text>
            <Text style={styles.subTitle}>Phone: (800) 641-2427</Text>
          </View>
  
          {/* Applicator Details */}
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>Applicator: {applicator}</Text>
              <Text>Owner/Operator #: {ownerOperatorNumber}</Text>
              <Text>Project: {project}</Text>
              <Text>Address: {address}</Text>
              <Text>City: {city}</Text>
            </View>
            <View style={styles.column}>
              <Text>Application Cert. #: {applicatorId}</Text>
              <Text>Date: {applicationDate}</Text>
              <Text>State: {state}</Text>
              <Text>Temperature: {temperature}</Text>
              <Text>Wind: {wind}</Text>
              <Text>Size of Area Treated: {areaTreated} {areaUnit}</Text>
              <Text style={styles.boldText}>
                 Used Restricted General Use Pesticide:{" "}
              <Text style={usedRestrictedPesticide === 'Yes' ? styles.redText : null}>
                {usedRestrictedPesticide}
               </Text>
              </Text>
            </View>
          </View>
  
          {/* Checkbox Section */}
          <View style={styles.section}>
            <Text style={styles.subTitle}>Select Applicable Options:</Text>
            <View style={styles.checkboxContainer}>
              {Object.entries(checkboxMap).map(([label, key], index) => (
                <View key={index} style={styles.checkboxItem}>
                  <Text>{checkboxes[key] ? '[X]' : '[ ]'} {label}</Text>
                </View>
              ))}
            </View>
          </View>
  
          {/* Chemical Table */}
          <View style={styles.section}>
            <Text style={styles.subTitle}>Chemicals Applied:</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>Product</Text>
                <Text style={styles.tableCell}>EPA #</Text>
                <Text style={styles.tableCell}>Lot #</Text>
                <Text style={styles.tableCell}>Rate</Text>
                <Text style={styles.tableCell}>Per</Text>
                <Text style={styles.tableCell}>Total Applied</Text>
                <Text style={[styles.tableCell, styles.tableCellLast]}>
                  Total Concentration
                </Text>
              </View>
              {chemicals.map((chemical, index) => (
                <View key={index} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{chemical.product || '-'}</Text>
                  <Text style={styles.tableCell}>{chemical.epaNumber || '-'}</Text>
                  <Text style={styles.tableCell}>{chemical.lotNumber || '-'}</Text>
                  <Text style={styles.tableCell}>{chemical.rate || '-'}</Text>
                  <Text style={styles.tableCell}>{chemical.per || '-'}</Text>
                  <Text style={styles.tableCell}>
                    {chemical.totalApplied || '-'}
                  </Text>
                  <Text style={[styles.tableCell, styles.tableCellLast]}>
                    {chemical.totalConc || '-'}
                  </Text>
                </View>
              ))}
            </View>
          </View>
  
          {/* Notes Section */}
          <View style={styles.notesSection}>
            <Text style={styles.subTitle}>Application Notes:</Text>
            <Text>{notes}</Text>
          </View>
        </Page>
      </Document>
    );
  };
  
  export { ChemicalApplicationPDF };
  
  