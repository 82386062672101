import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDocs, collection, query, where, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../../Firebase Functions/firebase';
import './email-sender-styles.css';

function AMEmailSender({ job, onClose }) {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        setMessage('');
        if (job?.property) {
          const propertiesRef = collection(db, 'Properties');
          const q = query(propertiesRef, where("Property Name", "==", job.property));
          const querySnapshot = await getDocs(q);
  
          if (!querySnapshot.empty) {
            const propertyDoc = querySnapshot.docs[0];
            const propertyData = propertyDoc.data();
            let amEmails = propertyData["AM Email List"] || "";
            let rmEmails = propertyData["RM Email List"] || "";
            let dmEmails = propertyData["DM Email List"] || "";
  
            let emailList = new Set();
  
            if (amEmails || rmEmails) {
              if (amEmails) {
                amEmails.split(',').forEach(email => emailList.add(email.trim()));
              }
              if (rmEmails) {
                rmEmails.split(',').forEach(email => emailList.add(email.trim()));
              }
            } else {
              dmEmails.split(',').forEach(email => emailList.add(email.trim()));
            }
            
  
            setTo(Array.from(emailList).join(', ') || 'No available emails');
          } else {
            setMessage("No document found for the provided Property Name.");
          }
        } else {
          setMessage("No property name provided in job.");
        }
      } catch (error) {
        console.error('Error fetching emails:', error);
        setMessage('Failed to retrieve emails due to an error.');
      }
    };
  
    fetchEmails();
  }, [job?.property]);

  const handleFirebaseUpdate = async () => {
    const storage = getStorage();
    let imageUrls = {};

    try {
      const newDocRef = doc(collection(db, "Services"));
      const newJobId = newDocRef.id;

      const imageFiles = Object.entries(job || {})
        .filter(([key, value]) => 
          ['image', 'secondImage', 'thirdImage', 'fourthImage', 'fifthImage'].includes(key) && 
          value instanceof File
        );

      const uploadPromises = imageFiles.map(async ([imageName, file]) => {
        const timestamp = Date.now();
        const path = `images/${newJobId}/${imageName}-${timestamp}`;
        const imageRef = ref(storage, path);
        
        try {
          const snapshot = await uploadBytes(imageRef, file);
          const url = await getDownloadURL(snapshot.ref);
          return { imageName, url };
        } catch (error) {
          console.error(`Error uploading ${imageName}:`, error);
          return null;
        }
      });

      const uploadResults = await Promise.all(uploadPromises);

      uploadResults.forEach(result => {
        if (result) {
          imageUrls[result.imageName] = result.url;
        }
      });

      const firebaseData = {
        supervisorName: job?.supervisorName || '',
        property: job?.property || '',
        customPropertyName: job?.customPropertyName || '',
        services: job?.serviceTypes?.join(', ') || '',
        customServiceDescription: job?.serviceTypes?.includes('Other') ? job.customServiceDescription : null,
        notes: job?.notes || '',
        date: job?.date || new Date().toISOString(),
        imageUrls: imageUrls,
        truckNumber: job?.truckNumber || '',
        truckDriver: job?.truckDriver || '',
        createdAt: serverTimestamp(),
        status: 'pending',
        createdBy: job?.createdBy || '',
        userType: job?.userType || '',
      };

      await setDoc(newDocRef, firebaseData);
      return newJobId;

    } catch (error) {
      console.error('Error in handleFirebaseUpdate:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isSubmitting) return; // Prevent duplicate submission
    setIsSubmitting(true);
    setMessage('Processing...');
  
    try {
      if (!to) {
        setMessage('Please select a recipient.');
        return;
      }
  
      if (!job) {
        setMessage('No job data available.');
        return;
      }
  
      const newJobId = await handleFirebaseUpdate();
      const jobLink = `https://rciservicenow.com/jobs/${newJobId}`;
      const emailData = {
        to: to.split(',').map(email => email.trim()),
        subject: `Approval Needed for a New Service Update for ${job.property || 'Unnamed Property'}`,
        text: `Please find the job report for ${job.property || 'Unnamed Property'}.\n\nYou can view the full report here: ${jobLink}`,
        html: `<p>Please find the job report for ${job.property || 'Unnamed Property'}.</p><p>You can view the full report <a href="${jobLink}">here</a>.</p>`
      };
  
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailData })
      });
  
      if (response.ok) {
        setMessage('Job created and email sent successfully!');
        setTimeout(() => {
          onClose();
          navigate('/supervisor-dashboard');
        }, 2000);
      } else {
        const errorData = await response.json();
        setMessage(`Failed to send email: ${errorData.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="email-modal">
      <div className="email-modal-content">
        <button className="close-modal" onClick={onClose}>&times;</button>
        <h2>Send Email</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="to">Recipients</label>
            <input
              type="text"
              id="to"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              placeholder="Enter email addresses separated by commas"
            />
          </div>
          <div className="button-group">
          <button type="submit" className="primary-button" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send Email'}
          </button>

            <button type="button" className="secondary-button" onClick={onClose}>Cancel</button>
          </div>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
}

export default AMEmailSender;
